import { useState, useEffect } from 'react';
import { remote } from '@boyuai/utils';

const { $get, isLogin } = remote;

export default function useComments(commentKey) {
  const [comments, setComments] = useState([]);
  const [count, setCount] = useState(null);
  useEffect(() => {
    if (!commentKey) return;
    $get(
      `/comment/${commentKey}${isLogin() ? '/with-like' : ''}`,
      {},
      { hideLoading: true }
    ).then(([_comments, _count]) => {
      setCount(_count);
      setComments(
        _comments.map((o) => ({
          ...o,
          $likeSnapshot: o.like,
          $selectedSnapshot: o.selected,
        }))
      );
    });
  }, [commentKey]);
  return [comments, count, setComments];
}
