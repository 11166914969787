import { useCallback, useEffect } from 'react';
import throttle from 'lodash/throttle';

/**
 * 当video全屏时，鼠标移动则显示控制bar
 */
export default function useMouseMoveShowBar(playerInstance, domRef) {
    let reHideControlbarTimeout;
    useEffect(() => {
        return () => {
            clearTimeout(reHideControlbarTimeout);
        };
    }, [reHideControlbarTimeout]);
    const handleMouseMove = useCallback(
        throttle(e => {
            if (
                playerInstance.current &&
                playerInstance.current.fullscreenService.getIsFullScreen()
            ) {
                const controlbar = domRef.current.querySelector(
                    '.prism-controlbar'
                );
                controlbar.style.display = 'block';
                clearTimeout(reHideControlbarTimeout);
                reHideControlbarTimeout = setTimeout(() => {
                    if (
                        playerInstance.current &&
                        playerInstance.current.fullscreenService.getIsFullScreen() &&
                        playerInstance.current.getStatus() !== 'pause'
                    ) {
                        controlbar.style.display = 'none';
                    }
                }, 3000);
            }
        }, 1000),
        []
    );
    return { handleMouseMove };
}
