import React from 'react';
import { remote } from '@boyuai/utils';
import Name from './name';
import Editor from './editor';

const { requestLogin, isLogin } = remote;

export default function UserCommentEditor({
  commentKey,
  routePrefix,
  onSubmitComment,
  anonymous,
  name: userName,
}) {
  const guest = !isLogin();
  return (
    <React.Fragment>
      {!guest && (
        <div>
          {!userName && !anonymous && (
            <div className="editor-disable-mask">
              <Name />
            </div>
          )}
        </div>
      )}
      {guest && (
        <div className="editor-disable-mask">
          <span className="link" onClick={requestLogin}>
            登录
          </span>{' '}
          后参与讨论
        </div>
      )}
      <Editor
        commentKey={commentKey}
        onSubmit={onSubmitComment}
        routePrefix={routePrefix}
      />
    </React.Fragment>
  );
}
