import React, { useEffect, useState, useCallback } from 'react';
import { Tooltip } from 'antd';
import { remote } from '@boyuai/utils';
import converter from '@boyuai/site-address-converter';
import StarredModal from './starred-modal';

import './message-count.less';

const { isLogin, $get } = remote;

interface Props {
  openInNewTab?: boolean;
  color?: string;
}

/**
 * MessageCount header 中的消息提醒
 * @openInNewTab bool default false
 */
function MessageCount({ openInNewTab = false, color = '#44B6FA' }: Props) {
  const [state, setState] = useState({
    selectedComments: [],
    isRenderModal: false,
    badge: 'none', // 'dot' | 'star
  });

  const [isHover, setIsHover] = useState(false);

  // 如果时学习平台，更新当前页；如果是实践平台，在新标签页中打开学习平台
  const onJump = useCallback(
    (path) => {
      const hostName = converter(
        'elites',
        process.env.REACT_APP_ENV as 'production' | 'sandbox' | 'development'
      );
      const url = `${hostName}${path}`;
      if (openInNewTab) {
        window.open(url, '_blank');
      } else {
        window.location.href = url;
      }
    },
    [openInNewTab]
  );
  useEffect(() => {
    (async () => {
      if (isLogin()) {
        const { totalCount, isSelectedUnread, selectedComments } = await $get(
          '/message/unread/count',
          {},
          { hideLoading: true }
        );
        const isRenderModal =
          selectedComments[0] &&
          selectedComments[0].length > 0 &&
          !selectedComments[0][0].popped;
        if (isSelectedUnread) {
          setState({
            selectedComments,
            isRenderModal,
            badge: 'star',
          });
        } else if (totalCount > 0) {
          setState({
            selectedComments,
            isRenderModal,
            badge: 'dot',
          });
        }
      }
    })();
  }, []);
  return (
    <React.Fragment>
      <Tooltip title="消息中心" placement="bottom">
        <div
          onClick={() => onJump('/user/message')}
          className="message-wrapper"
          onMouseOver={() => setIsHover(true)}
          onMouseOut={() => setIsHover(false)}
        >
          <svg
            width="39"
            height="39"
            viewBox="0 0 39 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="5"
              y="5"
              width="29"
              height="29"
              rx="8"
              fill={isHover ? color : '#F8F8F8'}
            />
            <path
              d="M20.7222 24.7C20.7222 24.8707 20.6906 25.0398 20.6292 25.1975C20.5678 25.3552 20.4778 25.4985 20.3643 25.6192C20.2508 25.74 20.116 25.8357 19.9677 25.901C19.8194 25.9664 19.6605 26 19.5 26C19.3395 26 19.1806 25.9664 19.0323 25.901C18.884 25.8357 18.7492 25.74 18.6357 25.6192C18.5222 25.4985 18.4322 25.3552 18.3708 25.1975C18.3094 25.0398 18.2778 24.8707 18.2778 24.7H14.611C14.449 24.7 14.2935 24.6315 14.179 24.5096C14.0644 24.3877 14 24.2224 14 24.05C14 23.8776 14.0644 23.7123 14.179 23.5904C14.2935 23.4685 14.449 23.4 14.611 23.4H15.2221V18.85C15.2221 17.7558 15.5928 16.6981 16.2662 15.8709C16.9396 15.0436 17.8707 14.5021 18.8889 14.3455V13.65C18.8889 13.4776 18.9533 13.3123 19.0679 13.1904C19.1825 13.0685 19.3379 13 19.5 13C19.6621 13 19.8175 13.0685 19.9321 13.1904C20.0467 13.3123 20.1111 13.4776 20.1111 13.65V14.3455C22.184 14.6614 23.7779 16.5581 23.7779 18.85V23.4H24.389C24.551 23.4 24.7065 23.4685 24.821 23.5904C24.9356 23.7123 25 23.8776 25 24.05C25 24.2224 24.9356 24.3877 24.821 24.5096C24.7065 24.6315 24.551 24.7 24.389 24.7H20.7222V24.7ZM22.5556 23.4V18.85C22.5556 17.988 22.2337 17.1614 21.6606 16.5519C21.0876 15.9424 20.3104 15.6 19.5 15.6C18.6896 15.6 17.9124 15.9424 17.3394 16.5519C16.7663 17.1614 16.4444 17.988 16.4444 18.85V23.4H22.5556Z"
              fill={isHover ? '#FFFFFF' : '#B3B5B7'}
            />
            {state.badge === 'star' && (
              <path
                d="M29.6093 3.74536C29.9804 3.01811 31.0196 3.01811 31.3907 3.74536L32.517 5.95239C32.6623 6.23711 32.935 6.43521 33.2507 6.48541L35.6977 6.8746C36.5041 7.00284 36.8252 7.99113 36.2482 8.56884L34.4973 10.322C34.2714 10.5482 34.1672 10.8687 34.217 11.1845L34.6031 13.6321C34.7303 14.4386 33.8896 15.0494 33.1619 14.6792L30.9534 13.5557C30.6685 13.4107 30.3315 13.4107 30.0466 13.5557L27.8381 14.6791C27.1104 15.0494 26.2697 14.4386 26.3969 13.6321L26.7829 11.1845C26.8328 10.8687 26.7286 10.5482 26.5027 10.322L24.7518 8.56884C24.1748 7.99113 24.4959 7.00284 25.3023 6.8746L27.7493 6.48541C28.065 6.43521 28.3377 6.23711 28.483 5.95239L29.6093 3.74536Z"
                fill="#FFA701"
              />
            )}
            {state.badge === 'dot' && (
              <circle cx="30" cy="10" r="6" fill="#FF4545" />
            )}
          </svg>
        </div>
      </Tooltip>
      {state.isRenderModal && (
        <StarredModal
          selectedComments={state.selectedComments}
          onJump={onJump}
        />
      )}
    </React.Fragment>
  );
}

export default MessageCount;
