/**
 * 拓展 AdminSearch 时，只需从上至下修改此文件
 */
import { remote } from '@boyuai/utils';
import { message } from 'antd';
import { MutableRefObject } from 'react';
import { SelectValue } from 'antd/es/select';
import { BaseSearchProps, BaseSearchDto } from './interfaces';

export enum BoyuSearchTypeEnum {
  course = 'course',
  lesson = 'lesson',
  exam = 'exam',
  group = 'group',
  quizQuestionTag = 'quizQuestionTag',
  user = 'user'
}

export enum BoyuSingleChoiceEnum {
  course = 'course',
  lesson = 'lesson',
  exam = 'exam',
  group = 'group',
  user = 'user'
}

export enum BoyuMultiChoiceEnum {
  quizQuestionTag = 'quizQuestionTag'
}

export enum BoyuGroupEnum {
  course = 'course'
}

export enum BoyuGroupContentEnum {
  lesson = 'lesson',
  exam = 'exam'
}

export type BoyuSearchType = keyof typeof BoyuSearchTypeEnum;
export type BoyuGroup = keyof typeof BoyuGroupEnum;
export type BoyuGroupContent = keyof typeof BoyuGroupContentEnum;

export interface BoyuSearchDto extends BaseSearchDto {}

export interface BoyuSingleChoiceProps<VT>
  extends Omit<BaseSearchProps<VT>, 'defaultValue' | 'value' | 'onChange'> {
  type: keyof typeof BoyuSingleChoiceEnum;
  defaultValue?: BoyuSearchDto;
  value?: BoyuSearchDto;
  onChange?: (value?: BoyuSearchDto) => void;
}

export interface BoyuMultiChoiceProps<VT>
  extends Omit<BaseSearchProps<VT>, 'defaultValue' | 'value' | 'onChange'> {
  type: keyof typeof BoyuMultiChoiceEnum;
  defaultValue?: BoyuSearchDto[];
  value?: BoyuSearchDto[];
  onChange?: (value?: BoyuSearchDto[]) => void;
}

export interface QuizQuestionTagChoiceProps<VT>
  extends BoyuMultiChoiceProps<VT> {
  type: 'quizQuestionTag';
}

export interface CourseSingleChoiceProps<VT> extends BoyuSingleChoiceProps<VT> {
  type: 'course';
}

export interface LessonSingleChoiceProps<VT> extends BoyuSingleChoiceProps<VT> {
  type: 'lesson';
  params: {
    groupId: number | string | undefined;
  };
}

export interface ExamSingleChoiceProps<VT> extends BoyuSingleChoiceProps<VT> {
  type: 'exam';
  params: {
    groupId: number | string | undefined;
  };
}

// 此 group 非彼 group，这里的业务定义是学生班级，而 AdminSearch 的 group 参数是个抽象概念
export interface GroupSingleChoiceProps<VT> extends BoyuSingleChoiceProps<VT> {
  type: 'group';
}

export interface UserSingleChoiceProps<VT> extends BoyuSingleChoiceProps<VT> {
  type: 'user';
}

export type AdminSingleChoiceProps<VT = SelectValue> =
  | CourseSingleChoiceProps<VT>
  | LessonSingleChoiceProps<VT>
  | ExamSingleChoiceProps<VT>
  | GroupSingleChoiceProps<VT>
  | UserSingleChoiceProps<VT>;

export type AdminMultiChoiceProps<
  VT = SelectValue
> = QuizQuestionTagChoiceProps<VT>;

export type AdminSearchProps = AdminSingleChoiceProps | AdminMultiChoiceProps;

export const getGroupTypeFromContentType: (
  type: BoyuGroupContent
) => BoyuGroup | null = type => {
  switch (type) {
    case 'lesson':
    case 'exam':
      return 'course';
    default:
      return null;
  }
};

export const convertBoyuSearchResultToDto: (
  type: BoyuSearchType,
  value: any
) => BoyuSearchDto = (type, value) => {
  switch (type) {
    case 'course':
    case 'lesson':
    case 'exam':
    case 'group':
    case 'quizQuestionTag':
      return {
        displayName: value.title,
        id: value.id
      };
    case 'user':
      return {
        displayName: `${value.realName || value.name}(${value.phone})`,
        id: value.id
      };
    default:
      return {
        displayName: null,
        id: null
      };
  }
};

export const injectDefaultProps: (
  props: AdminSearchProps
) => AdminSearchProps = props => {
  switch (props.type) {
    case 'course':
      return {
        ...props,
        placeholder: props.placeholder || '搜索您管理的课程'
      };
    case 'lesson':
      return {
        ...props,
        placeholder: props.placeholder || '搜索您管理的单元'
      };
    case 'exam':
      return {
        ...props,
        placeholder: props.placeholder || '搜索您管理的考试单元'
      };
    case 'group':
      return {
        ...props,
        placeholder: props.placeholder || '搜索您管理的班级'
      };
    case 'quizQuestionTag':
      return {
        ...props,
        placeholder: props.placeholder || '搜索题目标签'
      };
    case 'user':
      return {
        ...props,
        placeholder: props.placeholder || '搜索用户'
      };

    default:
      return props;
  }
};

export const createSearchPromise = ({
  props,
  keyword,
  cacheKeyRef
}: {
  props: AdminSearchProps;
  keyword?: string;
  cacheKeyRef?: MutableRefObject<string | number | undefined>;
}) => {
  let searchPromise = Promise.resolve([]);
  let shouldUpdate = true;

  switch (props.type) {
    case 'course': {
      searchPromise = fetchCourses({ keyword });
      break;
    }
    case 'lesson': {
      const { groupId } = props.params;
      if (!groupId || cacheKeyRef?.current === groupId) {
        shouldUpdate = false;
        break;
      }
      searchPromise = fetchLessons({
        courseId: groupId,
        keyword
      });
      if (cacheKeyRef) {
        cacheKeyRef.current = groupId;
      }
      break;
    }
    case 'exam': {
      const { groupId } = props.params;
      if (!groupId || cacheKeyRef?.current === groupId) {
        shouldUpdate = false;
        break;
      }
      searchPromise = fetchExams({
        courseId: groupId,
        keyword
      });
      if (cacheKeyRef) {
        cacheKeyRef.current = groupId;
      }
      break;
    }
    case 'group': {
      searchPromise = fetchGroups({ keyword });
      break;
    }
    case 'quizQuestionTag': {
      searchPromise = fetchQuizQuestionTags({ keyword });
      break;
    }
    case 'user': {
      searchPromise = fetchUsers({ keyword });
      break;
    }
    default:
      break;
  }

  return { searchPromise, shouldUpdate };
};

type BaseServiceProps = {
  keyword?: string;
};

export const fetchCourses = (props?: BaseServiceProps) => {
  return remote.$get('/learn/courses').then(data => {
    if (!data) return [];
    if (props?.keyword) {
      return data.filter((course: any) => course.title.includes(props.keyword));
    }
    return data;
  });
};

export const fetchLessons = ({
  courseId,
  keyword
}: { courseId: string | number } & BaseServiceProps) => {
  if (!courseId) {
    return Promise.resolve([]);
  }

  return remote.$get(`/learn/courses/${courseId}`).then(data => {
    if (!data) {
      message.info('获取单元列表失败，请检查是否拥有该课程的权限');
      return [];
    }
    if (keyword) {
      return data.lessons.filter((lesson: any) =>
        lesson.title.includes(keyword)
      );
    }
    return data.lessons;
  });
};

export const fetchExams = ({
  courseId,
  keyword
}: { courseId: string | number } & BaseServiceProps) => {
  return fetchLessons({ courseId, keyword }).then(data => {
    return data.filter?.((lesson: any) => lesson?.examConfig) || [];
  });
};

export const fetchGroups = (props?: BaseServiceProps) => {
  return remote.$get('/learn/admin/groups').then(data => {
    if (!data) return [];
    if (props?.keyword) {
      return data.filter((group: any) => group.title.includes(props.keyword));
    }
    return data;
  });
};

export const fetchQuizQuestionTags = (props?: BaseServiceProps) => {
  return remote.$get('/quiz-questions/tags').then(data => {
    if (!data) return [];
    if (props?.keyword) {
      return data.filter((tag: any) => tag.title.includes(props.keyword));
    }
    // tags 接口没分页，少显示一些
    return data.slice(0, 20);
  });
};

export const fetchUsers = (props?: BaseServiceProps) => {
  return remote.$get('/admin/users', { query: props?.keyword }).then(data => {
    if (!data) return [];
    return data[0];
  });
};
