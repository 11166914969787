import * as React from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';

import './comment-user.less';

const InfoMask =
  'https://staticcdn.boyuai.com/materials/2020/11/17/F_uh3yEvhS_b8TNvvfI3V.png!png';

const DEFAULT_PORTRAIT =
  'https://staticcdn.boyuai.com/user/portrait/2019/06/12/HV1escOlF_DXE3jfs-P4J.jpg';

export default class CommentUserInfo extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    userName: PropTypes.string,
  };
  render() {
    const { user, userName } = this.props;
    const name = user && user.name ? user.name : userName;
    return (
      <div>
        <Popover
          content={this.renderUserInfo()}
          title={null}
          // visible={true}
          overlayClassName='comment-user-popover'
          overlayStyle={{ backgroundImage: `url(${InfoMask})` }}
        >
          <span className='user'>{name || userName || '匿名用户'}</span>
        </Popover>
        {this.renderBadge()}
      </div>
    );
  }
  renderUserInfo() {
    const { portrait, slogan, tags, name, userName } = this.props.user || {};
    let tagTextArr;
    if (typeof tags === 'string') {
      tagTextArr = JSON.parse(tags);
    }
    if (typeof tags === 'object') {
      tagTextArr = tags;
    }
    const tagText = tagTextArr && tagTextArr.join('/');
    return (
      <React.Fragment>
        <div
          className='info-mask'
          style={{ backgroundImage: `url(${InfoMask}` }}
        />
        <div className='user-wrapper'>
          <img
            className='portrait-wrapper'
            src={portrait || DEFAULT_PORTRAIT}
            alt='头像'
          />
          <section className='info-wrapper'>
            <div className='name'>{name || userName || '匿名用户'}</div>
            {tagText && (
              <div>
                <span className='tags'>{tagText}</span>
              </div>
            )}
            <span className='slogan'>{slogan || '暂无个人简介'}</span>
          </section>
        </div>
      </React.Fragment>
    );
  }
  renderBadge() {
    const { user } = this.props;
    if (!user) {
      return null;
    }
    const { badge } = user;
    let content;
    let imgSrc;
    switch (badge) {
      case 'ta':
        content = '助教';
        imgSrc =
          'https://staticcdn.boyuai.com/materials/2020/08/07/EOdWlT1xLyJf0u3VsIZp1.png!png';
        break;
      case 'teacher':
        content = '教师';
        imgSrc =
          'https://staticcdn.boyuai.com/materials/2020/08/07/kKsX0gwV61X0W7EEMWpJm.png!png';
        break;
      case 'staff':
        content = '工作人员';
        imgSrc =
          'https://staticcdn.boyuai.com/materials/2020/08/07/obSOZV2AC2t4OkXzcfKRd.png!png';
        break;
      case 'developer':
        content = '开发人员';
        imgSrc =
          'https://staticcdn.boyuai.com/materials/2020/08/07/Hy8BlWFze24PdOdrIgQle.png!png';
        break;
      default:
        return null;
    }
    return (
      <Popover overlayClassName='comment-badge-popover' content={content}>
        <img className='badge' src={imgSrc} alt={content} />
      </Popover>
    );
  }
}
