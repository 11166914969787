import { useState, useEffect, useCallback, useRef } from 'react';
import QRCode from 'qrcode';
import { $get, $post } from '@boyuai/utils/es/remote';

const TIMEOUT_SECONDS = 20 * 60;

const useQrcode = (uuid, payment) => {
  const [image, setImage] = useState(null);
  const [paid, setPaid] = useState(false);
  const timeoutRef = useRef(false);

  const handleTimeout = useCallback(() => {
    timeoutRef.current = true;
    setImage('https://staticcdn.boyuai.com/materials/2021/04/12/Ar9wePDfMsQYcVD0vNoSc.png!png');
  }, []);

  useEffect(() => {
    if (!uuid || !payment) {
      return;
    }
    timeoutRef.current = false;
    let timer;
    let watchTimer; // 超过10分钟不再轮训结果
    $post(`/pay/prepay/${payment}`, { uuid }, { hideLoading: true })
      .then(QRCode.toDataURL)
      .then(setImage)
      .then(() => {
        const checker = async () => {
          if (timeoutRef.current) {
            return;
          }
          const paid = await $get(
            `/pay/${uuid}/paid`,
            {},
            { hideLoading: true }
          );
          if (paid) {
            setPaid(true);
          } else {
            timer = setTimeout(checker, 500);
          }
        };
        timer = setTimeout(checker, 500);
        watchTimer = setTimeout(handleTimeout, TIMEOUT_SECONDS * 1000);
      });
    return () => {
      timer && clearTimeout(timer);
      watchTimer && clearTimeout(watchTimer);
    };
  }, [uuid, payment]);

  return [image, paid];
};

export default useQrcode;
