import React, { useCallback, useEffect, useRef, useState } from 'react';
import Markdown from '@boyuai/markdown';
import 'katex/dist/katex.min.css';
import './preview-editor.less';

// 全清晰高度 + 渐变高度
// const MAX_HEIGHT = 270 + 165

// 自定义折叠
// foldHeight 折叠后高度，即MAX_HEIGHT
// maskHeight 渐变高度
// disableUnfold 是否禁用展开

export default function PreviewEditor({
    value,
    maskHeight,
    foldHeight,
    disableUnfold,
}) {
    const MAX_HEIGHT = foldHeight && maskHeight ? foldHeight : 270 + 165;
    const [fold, setFold] = useState(false);
    const [clicked, setClicked] = useState(false);
    const contentRef = useRef(null);

    // 获取评论区的高度，是否折叠，如果是点击过{展开阅读全部}，则不折叠
    const updateFold = useCallback(() => {
        if (contentRef && contentRef.current && !clicked) {
            const originalHeight = contentRef.current.getBoundingClientRect()
                .height;
            if (originalHeight > MAX_HEIGHT) {
                setFold(true);
            }
        }
    }, [MAX_HEIGHT, clicked]);

    useEffect(() => {
        updateFold();
    }, [value, updateFold]);

    return (
        <div className="preview-editor">
            <div
                className="preview-content"
                ref={contentRef}
                data-is-fold={fold}
                style={fold ? { maxHeight: MAX_HEIGHT } : null}
            >
                <Markdown content={value} onImageLoad={updateFold} />
                {fold && (
                    <div
                        className="gradient-mask"
                        style={maskHeight ? { height: maskHeight } : null}
                    />
                )}
            </div>
            {fold && !disableUnfold && (
                <div
                    className="fold-button"
                    onClick={() => {
                        setFold(false);
                        setClicked(true);
                    }}
                >
                    +展开阅读全部
                </div>
            )}
        </div>
    );
}
