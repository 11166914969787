import React, { Component } from 'react';
import { remote, getStorage } from '@boyuai/utils';
import { Dropdown, Menu, Popover, Modal, Button, Badge } from 'antd';
import {
  QuestionCircleOutlined,
  DownOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import { isLogin, $get, logout } from '@boyuai/utils/es/remote';
import { UserContext } from '@boyuai/utils/es/context/user-context';
import moment from 'moment';
// @ts-ignore
import UserInfo from '@boyuai/user-info';
// @ts-ignore
import CurrencyIntro from '@boyuai/currency-intro';
import MessageCount from './message-count';

import './base-header.less';

const DEFAULT_AVATAR =
  'https://staticcdn.boyuai.com/user/portrait/2019/06/12/HV1escOlF_DXE3jfs-P4J.jpg';

const jump = (url: string) => {
  window.location.href = `${process.env.REACT_APP_SUB_DIRECTORY}${url}`;
};

const expireHintStorage = getStorage('credit-expire-hint');

interface ExpireGroup {
  expiresAt: string;
  value: number;
}

export class BaseHeaderLeft extends Component {
  render() {
    return (
      <div className="base-header-left">
        <a href="#data-analysis" className="header-item">
          Python 数据分析
        </a>
        <div className="base-header-left-divider" />
        <a href="#ai" className="header-item">
          人工智能微专业
        </a>
        <div className="base-header-left-divider" />
        <a href="#public-class" className="header-item">
          精品公开课
        </a>
        <div className="base-header-left-divider" />
        <a href="#about" className="header-item">
          关于伯禹
        </a>
      </div>
    );
  }
}

export class HeaderFeedback extends Component {
  state = {
    feedbackVisible: false,
  };

  renderFeedbackModal() {
    return (
      <Modal
        className="header-feedback-modal"
        visible={this.state.feedbackVisible}
        onCancel={() => this.setState({ feedbackVisible: false })}
        footer={null}
        width="360px"
      >
        <div className="header-feedback-modal-box">
          <img
            className="header-feedback-modal-box-image"
            src="https://staticcdn.boyuai.com/materials/2021/01/08/JfD486afyk7RV2eQVv11U.png"
            alt="boyu_xiaoyu"
          />
          <div className="header-feedback-modal-box-text">
            亲，您遇到什么问题啦？
            <br />
            或者有什么功能建议吗？
            <br />
            欢迎反馈给我们，谢谢！
            <br />
            （请使用微信扫码进群）
            <br />
          </div>
        </div>
        <Button
          className="header-feedback-modal-box-button"
          key="submit"
          type="primary"
          onClick={() => this.setState({ feedbackVisible: false })}
        >
          确认
        </Button>
      </Modal>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div
          className="header-feedback-wrapper"
          onClick={() => this.setState({ feedbackVisible: true })}
        >
          问题反馈
        </div>
        {this.renderFeedbackModal()}
      </React.Fragment>
    );
  }
}

interface State {
  infoVisible: boolean;
  currencyIntroVisible: boolean;
  expireHintGroup?: ExpireGroup | null;
}

export class BaseHeaderRight extends Component<{}, State> {
  static contextType: any = UserContext;

  state: State = {
    infoVisible: false,
    currencyIntroVisible: false,
    expireHintGroup: undefined,
  };

  get isAdmin() {
    const { roles } = this.context.user;
    return (
      roles &&
      (roles.includes('admin') ||
        roles.includes('editor') ||
        roles.includes('commentManager'))
    );
  }

  get isImageAdmin() {
    const { roles } = this.context.user;
    return roles && roles.includes('imageAdmin');
  }

  get isQuizAdmin() {
    const { roles } = this.context.user;
    return roles && roles.includes('quiz');
  }

  async componentDidMount() {
    if (isLogin()) {
      this.fetchWalletInfo();
    }
    if (isLogin() && window.location.search.includes('isNew')) {
      this.setState({ infoVisible: true });
    }
  }

  async fetchWalletInfo() {
    const { value, credit } = await $get('/wallet', {});
    const creditExpireGroups: ExpireGroup[] = await $get(
      '/wallet/expires-soon',
      {}
    );
    this.context.updateBalance && this.context.updateBalance(Number(value));
    this.context.updateCredit && this.context.updateCredit(Number(credit));
    this.context.updateCreditExpire &&
      this.context.updateCreditExpire(creditExpireGroups);
    // 是否需要展示雨滴过期小红点
    // 已经展示过的就不再展示，过滤掉
    const group = creditExpireGroups.sort(
      (a, b) => moment(a.expiresAt).unix() - moment(b.expiresAt).unix()
    )[0];
    if (group && !expireHintStorage.get(group.expiresAt)) {
      this.setState({ expireHintGroup: group });
    }
  }

  onLogOut = () => {
    logout();
    window.location.reload();
  };

  onLogin = () => {
    localStorage.setItem('login-redirect', window.location.href);
    remote.requestLogin();
  };

  renderLogin() {
    return (
      <div className="right-login-wrapper">
        <HeaderFeedback />
        <div className="login-wrapper" onClick={this.onLogin}>
          登录/注册
        </div>
      </div>
    );
  }

  renderQuestionMark() {
    return (
      <div className="question-mark-wrapper">
        <Popover
          placement="bottomRight"
          content={
            <div
              className="header-question-mark-popover"
              onClick={() => this.setState({ currencyIntroVisible: true })}
            >
              什么是雨滴/云朵？
            </div>
          }
          title={null}
          trigger="hover"
        >
          <div className="question-mark">
            <QuestionCircleOutlined
              onClick={() => this.setState({ currencyIntroVisible: true })}
            />
          </div>
        </Popover>
        <CurrencyIntro
          visible={this.state.currencyIntroVisible}
          onClose={() => this.setState({ currencyIntroVisible: false })}
        />
      </div>
    );
  }

  renderMessageCount() {
    return <MessageCount />;
  }

  renderUserMenu() {
    if (!this.context.user) {
      // 有可能是undefined
      return null;
    }
    const { name, portrait, phone } = this.context.user;
    return (
      <Dropdown overlay={this.renderMenuContent()}>
        <div className="user-info">
          <img src={portrait || DEFAULT_AVATAR} alt="头像" className="avatar" />
          <div className="user-name">{name || phone}</div>
          <DownOutlined />
        </div>
      </Dropdown>
    );
  }

  renderMenuContent() {
    const { Item } = Menu;
    return (
      <Menu>
        {this.isAdmin && <Item onClick={() => jump('/admin')}>管理后台</Item>}
        {!this.isAdmin && this.isImageAdmin && (
          <Item onClick={() => jump('/admin/materials')}>图床上传</Item>
        )}
        {!this.isAdmin && this.isQuizAdmin && (
          <Item onClick={() => jump('/admin/quiz-questions')}>题目上传</Item>
        )}
        {this.renderUserCenterMenu()}
        <Item onClick={() => this.setState({ infoVisible: true })}>
          编辑资料
        </Item>
        <Item onClick={this.onLogOut}>退出登录</Item>
      </Menu>
    );
  }

  renderUserCenterMenu() {
    const { Item } = Menu;
    return [
      <Item key="user" onClick={() => jump('/user')}>
        个人中心
      </Item>,
      <Item key="progress" onClick={() => jump('/user/progress')}>
        学习进度
      </Item>,
      <Item key="note" onClick={() => jump('/user/notes')}>
        我的笔记
      </Item>,
      // <Item key="question" onClick={() => jump('/user/question')}>
      //     我的提问
      // </Item>,
      // <Item key="lesson" onClick={() => jump('/user/lessons')}>
      //     我的知识点
      // </Item>,
    ];
  }

  get creditContent() {
    const { credit } = this.context;
    const { expireHintGroup } = this.state;
    const count = expireHintGroup ? (
      <ExclamationCircleFilled style={{ color: '#F79345' }} />
    ) : null;
    return (
      <div className="cloud-wrapper">
        <Badge count={count}>
          <img
            src="https://staticcdn.boyuai.com/materials/2020/11/17/9xNgR5EOLnOh6xoWKXr5-.png"
            alt="雨滴"
            className="cloud-icon"
          />
        </Badge>
        <span className="desc-span">{`雨滴 ${
          credit >= 0 ? credit / 100 : '--'
        }`}</span>
      </div>
    );
  }

  renderWallet() {
    const { balance } = this.context;
    const { expireHintGroup } = this.state;
    return (
      <div className="wallet-wrapper" onClick={() => jump('/user/balance')}>
        <div className="cloud-wrapper">
          {expireHintGroup ? (
            <Popover
              content={
                <div
                  className="header-question-mark-popover"
                  onClick={() => jump('/user/balance')}
                >
                  有{Math.floor(expireHintGroup?.value / 100)}
                  雨滴即将过期，点击查看
                </div>
              }
              title={null}
              trigger="hover"
              onVisibleChange={(visible: boolean) => {
                if (visible) {
                  expireHintStorage.set(expireHintGroup?.expiresAt, '1', {
                    days: 365,
                  });
                }
              }}
            >
              {this.creditContent}
            </Popover>
          ) : (
            this.creditContent
          )}
          <img
            src="https://staticcdn.boyuai.com/materials/2020/11/17/C-1jO5-aquIR-Ne_bmvLP.png"
            alt="云朵"
            className="cloud-icon"
          />
          <span className="desc-span">{`云朵 ${
            balance >= 0 ? balance / 100 : '--'
          }`}</span>
        </div>
      </div>
    );
  }
  renderInfo() {
    return (
      <div className="info-wrapper">
        {this.renderWallet()}
        {this.renderQuestionMark()}
        {this.renderMessageCount()}
        {this.renderUserMenu()}
        {this.state.infoVisible && (
          <UserInfo
            visible={true}
            onCancel={() => this.setState({ infoVisible: false })}
            onSubmit={async (result: any) => {
              this.context.updateUser({
                ...this.context.user,
                ...result,
              });
              await this.fetchWalletInfo();
              this.setState({ infoVisible: false });
            }}
          />
        )}
      </div>
    );
  }

  render() {
    return (
      <div className="base-header-right">
        {isLogin() ? this.renderInfo() : this.renderLogin()}
      </div>
    );
  }
}

interface IBaseHeader {
  leftContent?: React.ReactChild;
  rightContent?: React.ReactChild;
  className?: string;
}

export default class BaseHeader extends Component<IBaseHeader> {
  render() {
    const { leftContent, rightContent, className } = this.props;
    return (
      <div className={`boyu-header-wrapper${className ? ` ${className}` : ''}`}>
        <header>
          <div className="header-left-wrapper">
            <div className="logo-wrapper">
              <div className="logo">
                <img
                  srcSet="https://staticcdn.boyuai.com/materials/2020/12/01/K9YNKx4hAZaP66-II_WJN.png,
                    https://staticcdn.boyuai.com/materials/2020/12/01/MMIRRfFETRcV8zdGiA8kV.png 1.5x,
                    https://staticcdn.boyuai.com/materials/2020/11/30/Sh_amoLfFNI0JnkZlkxEh.png 2x,
                    https://staticcdn.boyuai.com/materials/2020/12/01/iSeWIeqRqIOUWvgHloaHX.png 3x,
                    https://staticcdn.boyuai.com/materials/2020/12/01/GYzKdu84r3rGbKRrZCvb8.png 4x"
                  src="https://staticcdn.boyuai.com/materials/2020/12/01/K9YNKx4hAZaP66-II_WJN.png"
                  onClick={() => jump('/')}
                  alt="site-logo"
                />
              </div>
            </div>
            {leftContent !== undefined ? leftContent : <BaseHeaderLeft />}
          </div>
          {rightContent !== undefined ? rightContent : <BaseHeaderRight />}
        </header>
      </div>
    );
  }
}
