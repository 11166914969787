import { useCallback, useEffect, useState } from 'react';
import { remote } from '@boyuai/utils';
import { message } from 'antd';
import moment from 'moment';

interface Log {
    date: string;
    courseUUID: string;
    lessonUUID: string;
}

// 每日登录
export function useDailySignIn(active: boolean, messageDisabled: boolean = false) {
    const [logs, setLogs] = useState<Log[]>([]);

    const disabled = logs.filter(
        log => log.date === moment().format('YYYYMMDD')
    ).length;

    const dailySignIn = useCallback(async () => {
        if (!active) {
            return;
        }
        if (disabled) {
            return;
        }
        const res = await remote.$post(`/events/daily/daily-sign-in`, {
            payload: {},
        });
        if (res) {
            if (res.success) {
                if (!messageDisabled) {
                    message.success(res.msg);
                }
            }
            setLogs(res.logs);
        }
    }, [active, disabled, messageDisabled]);
    useEffect(() => {
        dailySignIn();
    }, [dailySignIn]);
    return [logs, dailySignIn];
}

// 学习平台——每日查看课程
const dailyViewCoursesRewardConfig = {
    upperLimit: 2, // 2次
};

export function useDailyViewCourses(courseUUID: string, messageDisabled: boolean = false) {
    const [logs, setLogs] = useState<Log[]>([]);

    // 超出次数或者已经获得过奖励
    const disabled =
        logs.filter(log => log.date === moment().format('YYYYMMDD')).length >=
        dailyViewCoursesRewardConfig.upperLimit ||
        logs.find(log => log.courseUUID === courseUUID);

    const dailyViewCourses = useCallback(async () => {
        if (!courseUUID) {
            return; // 不清除logs
        }
        if (disabled) {
            return; // 每天2次查看课程奖励
        }
        const res = await remote.$post(`/events/daily/daily-view-courses`, {
            courseUUID,
        });
        if (res) {
            if (res.success) {
                if (!messageDisabled) {
                    message.success(res.msg);
                }
            }
            setLogs(res.logs);
        }
    }, [disabled, courseUUID, messageDisabled]);
    useEffect(() => {
        dailyViewCourses();
    }, [dailyViewCourses]);
    return [logs, dailyViewCourses];
}

// 学习平台——每日查看知识点
const dailyViewLessonsRewardConfig = {
    upperLimit: 5, // 5次
};

export function useDailyViewLessons(lessonUUID: string, messageDisabled=false) {
    const [logs, setLogs] = useState<Log[]>([]);

    // 超出次数或者已经获得过奖励
    const disabled =
        logs.filter(log => log.date === moment().format('YYYYMMDD')).length >=
        dailyViewLessonsRewardConfig.upperLimit ||
        logs.find(log => log.lessonUUID === lessonUUID); // 已经获得过奖励

    const dailyViewLessons = useCallback(async () => {
        if (!lessonUUID) {
            return; // 不清除logs
        }
        if (disabled) {
            return; // 每天5次查看课程奖励
        }
        const res = await remote.$post(`/events/daily/daily-view-lessons`, {
            lessonUUID,
        });
        if (res) {
            if (res.success) {
                if (!messageDisabled) {
                    message.success(res.msg);
                }
            }
            setLogs(res.logs);
        }
    }, [disabled, lessonUUID, messageDisabled]);
    useEffect(() => {
        dailyViewLessons();
    }, [dailyViewLessons]);
    return [logs, dailyViewLessons];
}
