import React, { useCallback, useRef } from 'react';
import { Modal, message } from 'antd';
import { $post } from '@boyuai/utils/es/remote';
import Feedback from './form';

export default function FeedbackModal({ visible, onClose, uuid }) {
  const form = useRef(null);
  const onSubmit = useCallback(async () => {
    const values = await form.current.validateFields();
    const { reward, result } = await $post(
      '/course/feedbacks',
      { content: JSON.stringify(values), uuid },
      { throwException: 'showMessage' }
    );
    if (reward) {
      message.success(`评价反馈成功，获得${reward}雨滴奖励`);
    } else {
      message.success('评价反馈成功');
    }
    onClose(result);
  }, [onClose, uuid]);
  return (
    <Modal
      visible={visible}
      onCancel={() => onClose()}
      onOk={onSubmit}
      okText="提交"
      width="600px"
      title="主讲教师、理论助教、伯禹教研组都期待收到你的点评"
    >
      <Feedback ref={form} />
    </Modal>
  );
}
