import React, { PureComponent } from 'react';
import { Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { remote } from '@boyuai/utils';
import './starred-modal.less';

const modalMask = 'https://staticcdn.boyuai.com/materials/2020/11/11/SocZh-4CapBvBWaNu21g9.png!png';
const postStar = 'https://staticcdn.boyuai.com/materials/2020/11/11/7EGThYxzR5-eEL1DLd-8t.png!png';

const { $put } = remote;

interface Props {
    selectedComments: any[];
    onJump?: (link: string) => void;
}

interface State {
    visible: boolean;
    loading: boolean;
}

export default class StarredModal extends PureComponent<Props, State> {
    state = {
        visible: true,
        loading: false,
    };

    get commentInfo() {
        return this.props.selectedComments[0][0];
    }

    render() {
        return (
            <Modal
                title={null}
                visible={this.state.visible}
                onCancel={() => this.onCloseModal()}
                width={405}
                className="star-tip-modal"
                centered
                footer={null}
            >
                <img className="mask" src={modalMask} alt="mask" />
                <img className="star" src={postStar} alt="star" />
                <div className="star-wrapper">
                    <div className="congrats">恭喜！您的帖子被加精！</div>
                    {/* this.renderContent() */}
                    {this.renderJumpButton()}
                </div>
            </Modal>
        );
    }

    renderContent() {
        const { selectedComments } = this.props;
        if (selectedComments[1] > 1) {
            return null;
        }
        // const message = selectedComments[0]
        return <div className="content">主题帖标题</div>;
    }

    renderJumpButton() {
        const loading = <LoadingOutlined />;
        return (
            <div className="jump-button" onClick={() => this.onClickJump()}>
                {this.state.loading ? loading : '点击查看'}
            </div>
        );
    }

    onClickJump = async () => {
        this.setState({ loading: true });
        await $put(
            `/message/${this.commentInfo.id}/popped`,
            { popped: true },
            { hideLoading: true }
        );
        this.setState({ loading: false, visible: false });

        const { selectedComments, onJump } = this.props;
        if (selectedComments[1] === 1) {
            const { id, link } = this.commentInfo;
            await $put(
                `/message/${id}/read`,
                { read: true },
                { hideLoading: true }
            );
            if (onJump) {
                onJump(link);
            }
        } else {
            if (onJump) {
                onJump('/course/user/message');
            }
        }
    };

    onCloseModal = async () => {
        await $put(
            `/message/${this.commentInfo.id}/popped`,
            { popped: true },
            { hideLoading: true }
        );
        this.setState({ visible: false });
    };
}
