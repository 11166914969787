/* eslint-disable no-script-url */
import React, { useEffect, forwardRef, useImperativeHandle, useState } from 'react';
import { Modal } from 'antd';
import './captcha.less';

const Captcha = forwardRef(({ onFinish, onSuccess }, ref) => {
    useImperativeHandle(ref, () => ({
        getCaptcha() {
            return window.getNVCVal();
        },
        handleNext(code) {
            if (!code) {
                window.nvcReset();
                return;
            }
            setVisible(true);
            if (code === 400) {
                window.getNC().then(function () {
                    window._nvc_nc.upLang('cn', {
                        _startTEXT: "请按住滑块，拖动到最右边",
                        _yesTEXT: "验证通过",
                        _error300: "哎呀，出错了，点击<a href=\"javascript:__nc.reset()\">刷新</a>再来一次",
                        _errorNetwork: "网络不给力，请<a href=\"javascript:__nc.reset()\">点击刷新</a>",
                    })
                    window._nvc_nc.reset()
                })
            } else if (code === 600) {
                window.getSC().then(function () { });
            } else if (code === 700) {
                setWidth(530);
                window.getLC();
            } else if (code === 800 || code === 900) {
                window.nvcReset();
                setBlocked(true);
            }
        }
    }))

    useEffect(() => {
        const nvcCodeTest = /nvcCode=(\d*)/.exec(window.location.search);
        const dev = process.env.REACT_APP_ENV !== 'production' && !!nvcCodeTest;
        const nvcCode = nvcCodeTest && nvcCodeTest[1];
        const options = dev ? {
            appkey: 'CF_APP_1',
            scene: 'nvc_register',
            trans: { "key1": "code0", "nvcCode": nvcCode },
        } : {
                appkey: 'FFFF0N00000000008BBD',
                scene: 'nvc_message',
            };
        window.NVC_Opt = {
            // 无痕配置 && 滑动验证、刮刮卡、问答验证码通用配置
            ...options,
            isH5: false,
            popUp: false,
            renderTo: '#captcha',
            nvcCallback: data => {
                setTimeout(() => {
                    setVisible(false);
                    onSuccess(data);
                }, 1000);
            },
            language: 'cn',
            // 滑动验证长度配置
            customWidth: 300,
            // 刮刮卡配置项
            width: 300,
            height: 100,
            elements: [
                '//img.alicdn.com/tfs/TB17cwllsLJ8KJjy0FnXXcFDpXa-50-74.png',
                '//img.alicdn.com/tfs/TB17cwllsLJ8KJjy0FnXXcFDpXa-50-74.png'
            ],
            bg_back_prepared: '//img.alicdn.com/tps/TB1skE5SFXXXXb3XXXXXXXXXXXX-100-80.png',
            bg_front: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABQCAMAAADY1yDdAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAADUExURefk5w+ruswAAAAfSURBVFjD7cExAQAAAMKg9U9tCU+gAAAAAAAAAIC3AR+QAAFPlUGoAAAAAElFTkSuQmCC',
            obj_ok: '//img.alicdn.com/tfs/TB1rmyTltfJ8KJjy0FeXXXKEXXa-50-74.png',
            bg_back_pass: '//img.alicdn.com/tfs/TB1KDxCSVXXXXasXFXXXXXXXXXX-100-80.png',
            obj_error: '//img.alicdn.com/tfs/TB1q9yTltfJ8KJjy0FeXXXKEXXa-50-74.png',
            bg_back_fail: '//img.alicdn.com/tfs/TB1w2oOSFXXXXb4XpXXXXXXXXXX-100-80.png',
            upLang: {
                cn: {
                    _ggk_guide: "请摁住鼠标左键，刮出两面盾牌",
                    _ggk_success: "恭喜您成功刮出盾牌<br/>继续下一步操作吧",
                    _ggk_loading: "加载中",
                    _ggk_fail: ['呀，盾牌不见了<br/>请', "javascript:noCaptcha.reset()", '再来一次', '或', "http://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN", '反馈问题'],
                    _ggk_action_timeout: ['我等得太久啦<br/>请', "javascript:noCaptcha.reset()", '再来一次', '或', "http://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN", '反馈问题'],
                    _ggk_net_err: ['网络实在不给力<br/>请', "javascript:noCaptcha.reset()", '再来一次', '或', "http://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN", '反馈问题'],
                    _ggk_too_fast: ['您刮得太快啦<br/>请', "javascript:noCaptcha.reset()", '再来一次', '或', "http://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN", '反馈问题']
                }
            }
        }

        const nvc = document.createElement('script');
        nvc.src = 'https://g.alicdn.com/sd/nvc/1.1.112/guide.js';
        const script = document.getElementsByTagName('script')[0];
        script.parentNode.insertBefore(nvc, script);

        return () => script.parentNode.removeChild(nvc);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let timeout;
        const nvcQuery = () => {
            if (window.getNVCVal) {
                onFinish();
            } else {
                timeout = setTimeout(nvcQuery, 100);
            }
        };
        nvcQuery();

        return () => {
            if (timeout) clearTimeout(timeout);
        }
    }, [onFinish]);

    const [visible, setVisible] = useState(false);
    const [blocked, setBlocked] = useState(false);
    const [width, setWidth] = useState(340);

    return <div>
        <Modal
            visible={visible}
            onCancel={() => setVisible(false)}
            title="验证身份"
            footer={null}
            width={width}
        >
            {blocked && <div className="ui-captcha-blocked">
                <div>网络异常，请检查是否开启了科学上网</div>
                <div>如有疑问请联系客服</div>
                <div>
                    <img src="https://staticcdn.boyuai.com/materials/2020/08/27/DPmAdGXxFdA6vdB8XURPh.png!png" width="200px" alt="客服" />
                </div>
            </div>}
            {!blocked && <div id="captcha"></div>}
        </Modal>
    </div>
});

export default Captcha;
