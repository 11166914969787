import React, { PureComponent } from 'react';
import TimeAgo from 'react-timeago';
import zhCNStrings from 'react-timeago/lib/language-strings/zh-CN';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';

const formatter = buildFormatter(zhCNStrings);

export default class XTimeAgo extends PureComponent {
    render() {
        return <TimeAgo {...this.props} formatter={formatter} />;
    }
}
