import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Select } from 'antd';
import { LabeledValue } from 'antd/es/select';
import lodash from 'lodash';
import {
  AdminSearchProps,
  BoyuSearchDto,
  injectDefaultProps,
  convertBoyuSearchResultToDto,
  createSearchPromise,
  BoyuGroupContentEnum
} from './services';
import { formatSearchDto } from './utils';
import './styles.css';

export const AdminSearch = (_props: AdminSearchProps) => {
  const props = injectDefaultProps(_props);
  const {
    type,
    params,
    value: outerValue,
    defaultValue,
    onChange,
    ...antdProps
  } = props;
  // 搜索结果
  const [searchResults, setSearchResults] = useState<{
    loading: boolean;
    data: BoyuSearchDto[];
  }>({
    loading: false,
    data: []
  });

  // 当前 value
  const [value, setValue] = useState<BoyuSearchDto | BoyuSearchDto[]>();
  const antdValue: LabeledValue | LabeledValue[] | undefined = useMemo(() => {
    if (lodash.isArrayLikeObject(value)) {
      return value.map(v => formatSearchDto(v));
    } else if (value) {
      return formatSearchDto(value);
    }
    return undefined;
  }, [value]);

  // 当某些参数变化时清空 value
  const prevParamsRef = useRef(props.params);
  useEffect(() => {
    if (props.type in BoyuGroupContentEnum) {
      // 切换 group 时，清空 value
      // 初始化刚结束时的变化不触发联动清空
      if (
        prevParamsRef.current?.groupId !== undefined &&
        props.params!.groupId !== prevParamsRef.current.groupId
      ) {
        setValue(undefined);
        onChange?.(undefined);
      }
      // 清空 group 时重置搜索结果，避免 group 与选择的 value 不匹配
      if (props.params!.groupId === undefined) {
        setSearchResults({
          data: [],
          loading: false
        });
        searchResultsCacheKeyRef.current = undefined;
      }
    }

    prevParamsRef.current = props.params;
  }, [props.params, props.type, setValue, onChange]);

  // 衍生状态放在外部，避免 props 变化触发 effect
  const isControlled = Object.keys(props).some(key => key === 'value');
  const hasDefaultValue = Object.keys(props).some(
    key => key === 'defaultValue'
  );
  // 处理非受控模式下的默认值
  useEffect(() => {
    if (isControlled || !hasDefaultValue) {
      return;
    }
    // defaultValue 浅比较变化时可能是异步初始化结束，需重置状态
    setValue(defaultValue);
  }, [defaultValue, hasDefaultValue, isControlled]);
  // 同步受控模式下的 outerValue
  useEffect(() => {
    // 非受控模式下不消费 undefined
    if (!isControlled) {
      return;
    }
    setValue(outerValue); // 同步时跳过 onChange 事件，避免重复更新
  }, [isControlled, outerValue]);

  // 默认搜索结果出炉时，检查是否需要选中第一项
  const { defaultActiveFirstOption } = antdProps;
  const [firstOptionInitialized, setFirstOptionInitialized] = useState(false);
  const firstOption = searchResults.data[0];
  useEffect(() => {
    if (defaultActiveFirstOption && firstOption && !firstOptionInitialized) {
      setValue(firstOption);
      // @ts-ignore: 省略 props.type === 'xxx' 的判断
      onChange?.(firstOption);
      setFirstOptionInitialized(true);
    }
  }, [
    defaultActiveFirstOption,
    firstOption,
    firstOptionInitialized,
    setValue,
    onChange
  ]);

  // 生成默认的下拉列表
  // 根据不同业务参数控制 cache，保险起见不依赖其它 effect 的 ref
  const searchResultsCacheKeyRef = useRef<string | number>();
  useEffect(() => {
    setSearchResults(prev => ({
      loading: true,
      data: prev.data
    }));

    const { shouldUpdate, searchPromise } = createSearchPromise({
      // 模拟完整 props 从而获得 TS 语法支持
      props: { params, type } as AdminSearchProps,
      cacheKeyRef: searchResultsCacheKeyRef
    });

    if (shouldUpdate) {
      searchPromise.then(data => {
        if (lodash.isArrayLikeObject(data)) {
          setSearchResults({
            loading: false,
            data: data.map(d => convertBoyuSearchResultToDto(type, d))
          });
          return;
        }
      });
    }

    setSearchResults(prev => ({
      loading: false,
      data: prev.data
    }));
  }, [params, type]);

  // 处理搜索动作
  const handleSearch = lodash.debounce((keyword: string) => {
    setSearchResults(prev => ({
      loading: true,
      data: prev.data
    }));
    const { searchPromise } = createSearchPromise({ keyword, props });
    searchPromise.then(data => {
      if (lodash.isArrayLikeObject(data)) {
        setSearchResults({
          loading: false,
          data: data.map(d => convertBoyuSearchResultToDto(type, d))
        });
        return;
      }

      setSearchResults(prev => ({
        loading: false,
        data: prev.data
      }));
    });
  }, 300);

  return (
    <Select
      allowClear
      showArrow
      showSearch
      // dropdownMatchSelectWidth={200}
      {...antdProps}
      className={`admin-search${props.className ? ` ${props.className}` : ''}`}
      value={antdValue}
      onChange={antdValue => {
        if (lodash.isArrayLikeObject(antdValue)) {
          const newValue: BoyuSearchDto[] | undefined =
            antdValue &&
            antdValue.map(o => ({
              id: o.value,
              displayName: o.label as string
            }));
          setValue(newValue);
          // @ts-ignore: 省略 props.type === 'xxx' 的判断
          onChange?.(newValue);
        } else {
          const newValue: BoyuSearchDto | undefined = antdValue && {
            id: antdValue.value,
            displayName: antdValue.label as string
          };
          setValue(newValue);
          // @ts-ignore: 省略 props.type === 'xxx' 的判断
          onChange?.(newValue);
        }

        // 兼容一下老版本，onClear 是后面才出的
        if (!antdValue) {
          handleSearch('');
        }
      }}
      // onClear={() => {
      //   handleSearch('');
      //   antdProps.onClear?.();
      // }}
      loading={searchResults.loading || props.loading}
      labelInValue
      options={searchResults.data.map(formatSearchDto)}
      filterOption={false} // 调用搜索 API 替代 optionFilterProp="label"
      onSearch={value => {
        handleSearch(value);
        antdProps.onSearch?.(value);
      }}
    />
  );
};
