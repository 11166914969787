import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, message, Select, Tooltip } from 'antd';
import removeMarkdown from 'remove-markdown';
import { remote } from '@boyuai/utils';
import './bottom-bar.less';

const askActive =
  'https://staticcdn.boyuai.com/materials/2020/11/17/nkZKHFrjs5wyJDk8xO76H.png!png';
const chatActive =
  'https://staticcdn.boyuai.com/materials/2020/11/17/dBpyNOPY7UxlNtsm-487s.png!png';
const noteActive =
  'https://staticcdn.boyuai.com/materials/2020/11/17/gg2kKxdkL65RX8qxZoTCv.png!png';
const tooltipBackground =
  'https://staticcdn.boyuai.com/materials/2020/11/17/T2RJotmzE67z9z_Hvdw6l.png!png';

const { $post } = remote;
const { Option } = Select;

class ErrorMessage extends React.PureComponent {
  render() {
    return <span>{`获得${this.props.rewardCredit}雨滴奖励`}</span>;
  }
}
export default class BottomBar extends Component {
  state = {
    submitAttr: null,
    isNotSelected: false
  };
  static propTypes = {
    value: PropTypes.object.isRequired,
    commentKey: PropTypes.string.isRequired,
    parentId: PropTypes.number,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func
  };
  static defaultProps = {
    parentId: 0,
    onCancel: () => {},
    onSubmit: () => {}
  };
  submit = async () => {
    if (this.props.parentId || this.state.submitAttr) {
      const { commentKey, parentId, onSubmit, routePrefix } = this.props;
      const { text, value, error } = this.checkCommentContent();
      if (!error) {
        const comment = await $post(
          `/comment/${commentKey}`,
          {
            content: value,
            text: text,
            parentId,
            site: process.env.REACT_APP_SITE,
            type: this.state.submitAttr || 'talk',
            link:
              routePrefix.indexOf('oj/') === 0
                ? `/${routePrefix}#comment-[uuid]` // OJ题专属讨论区链接
                : `/${routePrefix}/${commentKey}#comment-[uuid]` // 课程讨论区链接
          },
          { throwException: 'showMessage' }
        );
        message.success('发表成功');
        if (comment.rewardCredit && comment.rewardCredit > 0) {
          message.success(<ErrorMessage rewardCredit={comment.rewardCredit} />);
        }
        onSubmit(comment);
      } else {
        message.error(error);
      }
    } else {
      this.setState({ isNotSelected: true });
    }
  };
  render() {
    const { parentId, onCancel } = this.props;
    return (
      <div
        className="bottom-bar"
        data-not-selected={this.state.isNotSelected}
        data-has-parent={!!parentId}
      >
        {parentId ? <Button onClick={onCancel}>取消</Button> : null}
        {!parentId && this.renderAttrSelect()}
        <Button type="primary" onClick={this.submit} className="submit-button">
          发表
        </Button>
      </div>
    );
  }

  checkCommentContent() {
    const value = this.props.value.current;

    if (!value || !value.trim()) {
      return { error: '请输入评论内容' };
    }

    const content = value.trim();

    const text = removeMarkdown(value);

    // 如果是笔记，没有100字符则不能提交
    if (
      text.length < 100 &&
      this.state.submitAttr === 'note' &&
      !/!\[.*\]\(.+\)/.test(value) // 包含图片例外
    ) {
      return { error: '笔记必须大于100字符' };
    }

    return { value: content, text };
  }

  renderAttrSelect() {
    const selectStyle = {
      width: '140px',
      right: '-13px'
    };
    const title = (
      <div
        className="bottom-bar-tooltip"
        style={{ backgroundImage: `url(${tooltipBackground})` }}
      >
        <div className="title">
          如果你在学习的过程中有任何疑问,可选择"提问",每天都会有助教解答~
        </div>
      </div>
    );
    return (
      <Tooltip
        title={title}
        visible={!localStorage.getItem('askReminded')}
        placement="left"
        overlayClassName="bottom-bar-tooltip-overlay"
        // 要把弹框渲染在当前节点上，不然切换tab时弹框还会出现
        getPopupContainer={trigger => trigger}
      >
        <Select
          placeholder="*请选择类别"
          onChange={this.handleSelectChange}
          style={selectStyle}
          onFocus={() => localStorage.setItem('askReminded', true)}
        >
          {this.renderAttrOptions()}
        </Select>
      </Tooltip>
    );
  }

  renderAttrOptions() {
    const attrArr = [
      { title: '提问', value: 'question', icon: askActive },
      { title: '笔记', value: 'note', icon: noteActive },
      { title: '闲聊', value: 'talk', icon: chatActive }
    ];
    const options = attrArr.map(attr => {
      return (
        <Option key={attr.value} value={attr.value} className="attr-option">
          <img src={attr.icon} alt={attr.value} />
          <div>{attr.title}</div>
        </Option>
      );
    });
    return options;
  }

  handleSelectChange = value => {
    this.setState({ submitAttr: value, isNotSelected: false });
  };
}
