import React from 'react';
import {
    useDailyViewLessons,
    useDailyViewCourses,
    useDailySignIn
} from './hooks';
import { useRouteMatch } from 'react-router-dom';

interface Props {
    activeList: Array<'daily-view-lessons' | 'daily-view-courses' | 'daily-sign-in'>;
    messageDisabled: boolean;
}

export default function DailyCreditHelper(props: Props) {
    const { activeList, messageDisabled } = props;
    const lessonMatch: any = useRouteMatch('/course/:courseUUID/lesson/:lessonUUID');
    const courseMatch: any = useRouteMatch('/course/:courseUUID');
    // console.log(lessonMatch, courseMatch);
    const dailyViewLessonsActive =
        activeList && !!activeList.find(item => item === 'daily-view-lessons');
    useDailyViewLessons(
        dailyViewLessonsActive
            ? lessonMatch && lessonMatch.params && lessonMatch.params.lessonUUID
            : null,
        messageDisabled
    );
    const dailyViewCoursesActive =
        activeList && !!activeList.find(item => item === 'daily-view-courses');
    useDailyViewCourses(
        dailyViewCoursesActive &&
            courseMatch &&
            courseMatch.isExact &&
            courseMatch.params &&
            courseMatch.params.courseUUID,
        messageDisabled
    );
    const dailySignInActive =
        activeList && !!activeList.find(item => item === 'daily-sign-in');
    useDailySignIn(
        dailySignInActive,
        messageDisabled);

    return <div></div>;
}
