import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { AlipayOutlined, WechatOutlined } from '@ant-design/icons';
import useQrcode from './qrcode';
import './index.less';

function Step1(props) {
  const { cost, uuid, toStep2 } = props;

  const [payment, setPayment] = useState('alipay');
  const [qrcode, paid] = useQrcode(uuid, payment);

  useEffect(() => {
    if (paid) {
      toStep2();
    }
  }, [paid, toStep2]);

  return (
    <div className="step1">
      <div className="tips">请选择支付方式</div>
      <div className="payway">
        <Button
          type={payment === 'alipay' ? 'primary' : 'default'}
          onClick={() => setPayment('alipay')}
        >
          <AlipayOutlined />
          支付宝
        </Button>
        <Button
          type={payment === 'wxpay' ? 'primary' : 'default'}
          onClick={() => setPayment('wxpay')}
          style={{ marginLeft: '10px' }}
        >
          <WechatOutlined />
          微信支付
        </Button>
      </div>
      <div className="qrcode">
        {qrcode && <img src={qrcode} alt="支付二维码" />}
      </div>
      {payment === 'wxpay' ? (
        <div className="explanation">
          <div className="scan-tips wxpay">请使用微信扫码支付</div>
          <div className="price wxpay">{cost} 元</div>
        </div>
      ) : (
        <div className="explanation">
          <div className="scan-tips alipay">请使用支付宝扫码支付</div>
          <div className="price alipay">{cost} 元</div>
        </div>
      )}
    </div>
  );
}

Step1.propTypes = {
  cost: PropTypes.number.isRequired,
  toStep2: PropTypes.func.isRequired,
  uuid: PropTypes.string,
};

export default Step1;
