import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  Button,
  Input,
  Modal,
  message,
  Timeline,
  Tooltip,
  Typography
} from 'antd';
import { InputProps as AntdInputProps } from 'antd/lib/input';
import { CloudSyncOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { remote } from '@boyuai/utils';
// @ts-ignore
import ScrollToBottom, { useScrollToBottom } from 'react-scroll-to-bottom';
import styles from './index.module.css';

export interface AliyunVidInputProps extends Omit<AntdInputProps, 'onChange'> {
  value?: string;
  onChange?: (value: string) => void;
}

// 长期来看这个组件不该存在，应该由后端自动处理
export const AliyunVidInput = (props: AliyunVidInputProps) => {
  const [ossUrl, setOssUrl] = useState<string>();
  const [videoTitle, setVideoTitle] = useState<string>();
  const [modalVisible, setModalVisible] = useState(false);
  const [finished, setFinished] = useState(false);
  const [queryTime, setQueryTime] = useState(0);
  const querying = queryTime > 0;
  const syncTaskIdRef = useRef(); // "视频同步"任务 ID
  const queryTaskIdRef = useRef<number>(); // "获取同步结果"任务 ID
  const [queryLogs, setQueryLogs] = useState<string[]>([]);
  const scrollToBottom = useScrollToBottom();

  // 停止轮询同步结果
  const stopQuerying = useCallback(() => {
    setQueryTime(0);
    window.clearTimeout(queryTaskIdRef.current);
  }, []);

  useEffect(() => {
    window.clearTimeout(queryTaskIdRef.current);
    if (queryTime === 0) return;

    // 查询超过一定次数时自动关闭
    // if (queryTime === 1000) {
    //   setQueryTime(0);
    //   return;
    // }

    queryTaskIdRef.current = window.setTimeout(() => {
      // demo
      // setQueryTime((t) => t + 1);
      // setQueryLogs((logs) => logs.concat(['querying']));

      remote
        .$get(`/public-videos/oss-to-vod-jobs/${syncTaskIdRef.current}`)
        .then(data => {
          if (!data || data.succeeded === undefined) {
            stopQuerying();
            return;
          }
          if (data.logs && data.logs.length) {
            setQueryLogs(data.logs.filter((log: string) => !!log));
          } else {
            setQueryLogs(logs => logs.concat(['同步中...']));
          }
          if (data.succeeded === false) {
            setQueryTime(t => t + 1);
            return;
          }
          const logs: string[] = data.logs ?? [];
          let vid;
          logs.forEach(log => {
            const hasVid = log.includes('"success": true');
            if (hasVid) {
              const result = JSON.parse(log);
              vid = result.vid;
            }
          });
          if (!vid) {
            message.error('同步失败');
            stopQuerying();
            return;
          }
          props.onChange?.(vid);
          setFinished(true);
          stopQuerying();
          setModalVisible(false);
        })
        .catch(() => {
          stopQuerying();
        });
    }, 2000);
  }, [props.onChange, queryTime, stopQuerying]);

  useEffect(() => {
    scrollToBottom();
  }, [queryLogs, scrollToBottom]);

  const sync = () => {
    // 发起同步任务，成功后开始轮询同步结果
    remote
      .$post('/public-videos/oss-to-vod-jobs', {
        url: ossUrl,
        title: videoTitle
      })
      .then(data => {
        if (!data) {
          return;
        }
        if (!data.jobName) {
          message.error('同步任务发起失败');
          return;
        }
        // 开启轮询，查询同步任务结果
        setQueryTime(1);
        setQueryLogs(['开始同步']);
        syncTaskIdRef.current = data.jobName;
      });
  };

  return (
    <Fragment>
      <Input
        placeholder="请输入阿里云 VoD 视频点播 ID"
        {...props}
        value={props.value}
        onChange={e => {
          if (props.onChange) {
            props.onChange(e.target.value);
            return;
          }
        }}
        suffix={
          <Fragment>
            <Tooltip title={<Fragment>同步 OSS 视频</Fragment>}>
              <CloudSyncOutlined
                style={{ color: 'rgba(0,0,0,.45)', marginRight: 8 }}
                onClick={() => {
                  setModalVisible(true);
                }}
              />
            </Tooltip>
            <Tooltip
              title={
                <Fragment>
                  查看
                  <Button
                    type="link"
                    style={{
                      padding: 0
                    }}
                    onClick={() =>
                      window.open(
                        'https://public-doc.boyuai.com/GyRgZV2NV7Fq9Faq.html'
                      )
                    }
                  >
                    详细说明
                  </Button>
                </Fragment>
              }
            >
              <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
            </Tooltip>
          </Fragment>
        }
      />
      <Modal
        visible={modalVisible}
        title="阿里云 OSS Video to Vod"
        maskClosable={false}
        confirmLoading={querying}
        onCancel={() => {
          if (querying) {
            Modal.confirm({
              content: '关闭后需要重新同步，请确认您的操作',
              onCancel: () => {},
              onOk: () => {
                setModalVisible(false);
                stopQuerying();
              }
            });
            return;
          }

          setModalVisible(false);
        }}
        onOk={() => {
          // demo
          // setQueryTime(1);
          if (finished) {
            Modal.confirm({
              content: '视频已同步完成，是否重新同步？',
              onCancel: () => {
                setModalVisible(false);
              },
              onOk: () => {
                setFinished(false);
                sync();
              }
            });
            return;
          }
          sync();
        }}
      >
        <Typography.Paragraph>
          本功能可以将阿里云 OSS 中存放的视频同步至 VoD{' '}
          视频点播模块，同步成功后自动填入 VoD 视频点播 ID。
        </Typography.Paragraph>
        <Input
          value={videoTitle}
          onChange={e => {
            setVideoTitle(e.target.value);
          }}
          placeholder="请输入视频名称"
          disabled={querying}
        />
        <Input
          value={ossUrl}
          onChange={e => {
            setOssUrl(e.target.value);
          }}
          placeholder="请输入阿里云 OSS 视频地址"
          disabled={querying}
          style={{ marginTop: 8 }}
        />
        <ScrollToBottom
          className={queryLogs.length ? styles['scroll-to-bottom'] : undefined}
        >
          <Timeline className={styles['vid-timeline']}>
            {queryLogs.map((log, index) => (
              <Timeline.Item key={index}>{log}</Timeline.Item>
            ))}
          </Timeline>
        </ScrollToBottom>
      </Modal>
    </Fragment>
  );
};
