export default class SizeComponent {
    constructor(onChange) {
        this.onChange = onChange;
        this.size = 'normal';
    }
    createEl(el) {
        const eleControlbar = el.querySelector('.prism-controlbar');
        const wrapper = document.createElement('div');
        wrapper.className = 'size-components';
        wrapper.style.cssFloat = 'right';
        wrapper.style.color = '#fff';
        wrapper.style.height = '35px';
        wrapper.style.marginTop = '11px';
        wrapper.style.paddingRight = '10px';
        wrapper.style.cursor = 'pointer';
        const label = document.createElement('div');
        label.className = 'current-size';
        label.appendChild(document.createTextNode('剧场模式'));
        wrapper.appendChild(label);
        wrapper.addEventListener('click', this.handleClick);
        eleControlbar.appendChild(wrapper);
        this.labelDOM = label;
    }
    handleClick = () => {
        this.size = this.size === 'normal' ? 'wide' : 'normal';
        this.onChange(this.size);
        this.labelDOM.innerText =
            this.size === 'normal' ? '剧场模式' : '普通模式';
    };
}
