import { useMemo } from 'react';
import moment from 'moment';

const getChildren = (item, children, hash, keyword) => {
  const result = {
    ...item,
    children: [],
    $keywordMath: item?.content?.indexOf && item?.content?.indexOf(keyword) >= 0
  };
  children.forEach(o => {
    if (o.parentId === item.id) {
      const child = getChildren(o, children, hash, keyword);
      result.children.push(child);
    }
  });
  if (!result.$keywordMath) {
    result.$keywordMath = result.children.some(c => c.$keywordMath);
  }

  if (keyword && result.$keywordMath && result?.content?.replaceAll) {
    result.content = result.content.replaceAll(
      keyword,
      `<span class="highlight">${keyword}</span>`
    );
  }

  result.$meAndChildrenLikes =
    result.$likeSnapshot +
    result.children.reduce((p, item) => p + (item.$meAndChildrenLikes || 0), 0);
  result.$meAndChildrenSelected =
    result.$selectedSnapshot ||
    result.children.reduce(
      (p, item) => p || item.$meAndChildrenSelected,
      false
    );
  result.$meAndChildrenUrlSelected =
    hash === `#comment-${result.uuid}` ||
    result.children.reduce(
      (p, item) =>
        p || hash === `#comment-${item.uuid}` || item.$meAndChildrenUrlSelected,
      false
    );
  return result;
};

const getScore = (item, keyword) => {
  // 精华权重10，点赞权重1，回复1，字数大于10个字权重5，提问2，笔记2，主贴匹配搜索关键词5
  let score =
    item.$meAndChildrenSelected * 10 +
    item.$meAndChildrenLikes +
    item.children.length +
    Number(item.text.length > 10) * 5 +
    Number(item.type === 'note' || item.type === 'question' || 0) * 2 +
    Number(item?.content?.indexOf && item?.content?.indexOf(keyword) >= 0) * 5;
  if (Number.isNaN(score)) {
    // TODO 需要报错
    score = 0;
  }
  return { ...item, score };
};

export default function useNestedComments(comments, filteredType, keyword) {
  const hash = window.location.hash;
  const nestedComments = useMemo(() => {
    if (!comments.length) {
      return [];
    }

    const roots = [];
    const children = [];
    comments.forEach(c => {
      if (c.parentId) {
        children.push(c);
      } else {
        roots.push(c);
      }
    });

    const items = roots
      .map(item => getChildren(item, children, hash, keyword))
      .filter(i => i.$keywordMath)
      .map(i => getScore(i, keyword))
      .filter(item => {
        if (filteredType.type === 'all') {
          return true;
        }
        if (filteredType.type === 'selected') {
          // 如果有任何子评论加精，全部对话流在精华 TAB 显示
          return item.$meAndChildrenSelected;
        }
        return item.type === filteredType.type;
      });
    const sortedItems = items.sort((a, b) => {
      const primaryOrder = (b.$primaryOrder || 0) - (a.$primaryOrder || 0);
      if (primaryOrder !== 0) {
        return primaryOrder;
      }

      // 高亮评论，也就是 url 链接里有的，排在第一个
      if (a.$meAndChildrenUrlSelected) {
        return -1;
      }

      // 如果分数相同，那就时间最新的放在前面
      if (b.score === a.score && moment(b.createdAt).isBefore(a.createdAt)) {
        return -1;
      }
      return b.score - a.score;
    });

    return sortedItems;
  }, [comments, filteredType, hash, keyword]);
  return nestedComments;
}
