import React, { forwardRef } from 'react';
import { Form, Radio, Input } from 'antd';

const FeedbackForm = forwardRef((_, ref) => {
  return (
    <Form ref={ref}>
      <Form.Item
        label="你对这个课件设计的打分"
        name="ppt"
        rules={[{ required: true, message: '此项必填' }]}
      >
        <Radio.Group>
          <Radio.Button value="5">超优秀der</Radio.Button>
          <Radio.Button value="4">清楚明了</Radio.Button>
          <Radio.Button value="3">普普通通</Radio.Button>
          <Radio.Button value="2">略显杂乱</Radio.Button>
          <Radio.Button value="1">需要好好修改</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="你对这个讲者讲解的打分"
        name="present"
        rules={[{ required: true, message: '此项必填' }]}
      >
        <Radio.Group>
          <Radio.Button value="5">为讲者疯狂打Call</Radio.Button>
          <Radio.Button value="4">表达清楚</Radio.Button>
          <Radio.Button value="3">平淡无奇</Radio.Button>
          <Radio.Button value="2">晦涩难懂</Radio.Button>
          <Radio.Button value="1">讲者可以再练练</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="你的掌握程度"
        name="learn"
        rules={[{ required: true, message: '此项必填' }]}
      >
        <Radio.Group>
          <Radio.Button value="5">完整Get</Radio.Button>
          <Radio.Button value="4">勉强掌握</Radio.Button>
          <Radio.Button value="3">一知半解</Radio.Button>
          <Radio.Button value="2">基本不懂</Radio.Button>
          <Radio.Button value="1">我是谁我在哪儿</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="对本知识点教学内容的感想" name="note">
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        label="你有不满意的课后练习题吗（请填写题号和原因）"
        name="question"
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item label="其他感想" name="other">
        <Input.TextArea />
      </Form.Item>
    </Form>
  );
});

export default FeedbackForm;
