import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
  askActive,
  askIcon,
  noteActive,
  noteIcon,
  chatIcon,
  chatActive,
  starIcon,
  starActive,
  allIcon,
  allActive,
} from './type-image';

const filteredTypeArr = [
  {
    type: 'all',
    activeIcon: allActive,
    defaultIcon: allIcon,
    tip: '全部',
  },
  {
    type: 'selected',
    activeIcon: starActive,
    defaultIcon: starIcon,
    tip: '精华',
  },
  {
    type: 'question',
    activeIcon: askActive,
    defaultIcon: askIcon,
    tip: '提问',
  },
  {
    type: 'note',
    activeIcon: noteActive,
    defaultIcon: noteIcon,
    tip: '笔记',
  },
  {
    type: 'talk',
    activeIcon: chatActive,
    defaultIcon: chatIcon,
    tip: '闲聊',
  },
];

function useTypeFilter(onChange, getChangeRef) {
  const [value, setValue] = useState(filteredTypeArr[0]);
  const filterWrapperRef = useRef(null);
  const onClickFilter = useCallback(
    (_typeInfo) => {
      const typeInfo =
        typeof _typeInfo === 'string'
          ? filteredTypeArr.find((t) => t.type === _typeInfo)
          : _typeInfo;
      if (!typeInfo) {
        throw new Error('切换评论类型失败：无效的typeInfo');
      }
      setValue(typeInfo);
      onChange();
    },
    [onChange]
  );
  useEffect(() => {
    if (filterWrapperRef && filterWrapperRef.current) {
      // 要滚动的距离为当前选择器所在的相对位置加上当前视窗的滚动高度
      const commentRect = document
        .querySelector('.comment-list')
        .getBoundingClientRect();
      if (commentRect.height < window.innerHeight / 2) {
        return;
      }
      if (commentRect.bottom < commentRect.height) {
        return;
      }
      const bodyTop = window.scrollY;
      // 减去header的高度
      window.scrollTo(0, commentRect.top + bodyTop - 64);
    }
  }, [value]);
  useEffect(() => {
    if (getChangeRef) {
      getChangeRef(onClickFilter);
    }
  }, [getChangeRef, onClickFilter]);
  const node = (
    <div className="filter-wrapper" ref={filterWrapperRef}>
      {filteredTypeArr.map((typeInfo) => {
        const { activeIcon, defaultIcon, tip } = typeInfo;
        const isCurrenSelected = value.type === typeInfo.type;
        return (
          <div
            className="type-filter"
            data-is-selected={isCurrenSelected}
            key={typeInfo.type}
            onClick={() => onClickFilter(typeInfo)}
          >
            <img src={isCurrenSelected ? activeIcon : defaultIcon} alt={tip} />
            <span>{tip}</span>
          </div>
        );
      })}
    </div>
  );
  return [value, node];
}

export default useTypeFilter;
