import React, { Component } from 'react';
import { Modal, Input, message } from 'antd';
import { remote } from '@boyuai/utils';
import { UserContextModule } from '@boyuai/utils/es/context';

const { $patch } = remote;
export default class CommentName extends Component {
  state = {
    modal: false,
    name: '',
  };
  static contextType = UserContextModule.UserContext;

  changeUserName = async () => {
    const { name } = this.state;
    this.onCancel();
    const result = await $patch('/user', { name });
    if (result.reward) {
      message.success(`获得${result.reward}雨滴奖励`);
    }
    this.context.updateUser({
      ...this.context.user,
      name: result.name,
    });
  };
  onNameChange = (e) => this.setState({ name: e.target.value });
  onCancel = () => this.setState({ modal: false, name: '' });
  setAnonymous = () => {
    this.context.updateUser({
      ...this.context.user,
      anonymous: true,
    });
  };
  render() {
    return (
      <div className="set-user-name">
        <button onClick={() => this.setState({ modal: true })}>设置昵称</button>
        参与评论，或 <button onClick={this.setAnonymous}>匿名</button> 参与讨论
        <Modal
          title="设置昵称"
          visible={this.state.modal}
          cancelText="取消"
          okText="保存"
          onOk={this.changeUserName}
          onCancel={this.onCancel}
        >
          <Input placeholder="昵称" onChange={this.onNameChange} />
        </Modal>
      </div>
    );
  }
}
