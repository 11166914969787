import { LabeledValue } from 'antd/es/select';
import { BaseSearchDto } from './interfaces';

export const formatSearchDto: (
  value: BaseSearchDto
) => LabeledValue = value => {
  return {
    label: value.displayName,
    value: value.id
  };
};
