import React, { Component } from 'react';
import Markdown from '@boyuai/markdown';
import './index.less';

export default class AuthorIntro extends Component {
  render() {
    const { coverImage, name, title, content } = this.props.author;
    return (
      <div className="author">
        <div className="cover-image">
          <img src={coverImage} alt="作者图片" />
        </div>
        <div className="author-info">
          <h2>{name}</h2>
          <h3>{title}</h3>
          <Markdown content={content} />
        </div>
      </div>
    );
  }
}
