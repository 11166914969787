const askActive = 'https://staticcdn.boyuai.com/materials/2020/11/17/nkZKHFrjs5wyJDk8xO76H.png!png';
const chatActive = 'https://staticcdn.boyuai.com/materials/2020/11/17/dBpyNOPY7UxlNtsm-487s.png!png';
const noteActive = 'https://staticcdn.boyuai.com/materials/2020/11/17/gg2kKxdkL65RX8qxZoTCv.png!png';
const starActive = 'https://staticcdn.boyuai.com/materials/2020/11/17/AO4soZOy6zewOWoU1K1Rt.png!png';
const likeActive = 'https://staticcdn.boyuai.com/materials/2020/11/17/ynROVYHze8ljLK6uHLVse.png!png';
const allActive = 'https://staticcdn.boyuai.com/materials/2020/11/17/W4L4U8nGMhUDuJnkbuebd.png!png';
const askIcon = 'https://staticcdn.boyuai.com/materials/2020/11/17/IT3VogQMYpQCXIqFI0rpo.png!png';
const chatIcon = 'https://staticcdn.boyuai.com/materials/2020/11/17/BB0dvgVkd4012ingbkdQw.png!png';
const noteIcon = 'https://staticcdn.boyuai.com/materials/2020/11/17/hpwBPNMj5c2nvpfx-9FVM.png!png';
const starIcon = 'https://staticcdn.boyuai.com/materials/2020/11/17/Oal386fOuBARHZyX765WM.png!png';
const likeIcon = 'https://staticcdn.boyuai.com/materials/2020/11/17/ipU_cfFlSdebRna9-_tTp.png!png';
const allIcon = 'https://staticcdn.boyuai.com/materials/2020/11/17/CxjoNiwSaAvGITGycZTov.png!png';
const replyIcon = 'https://staticcdn.boyuai.com/materials/2020/11/17/NgOjiyyebGo29ty7t2BSi.png!png';
const linkIcon = 'https://staticcdn.boyuai.com/materials/2020/11/17/kd0zKPvPB0hPATwU0qBWp.png!png';
const expandIcon = 'https://staticcdn.boyuai.com/materials/2020/11/17/BUtJoFupdSxuZaYv_INcF.png!png';
const foldIcon = 'https://staticcdn.boyuai.com/materials/2020/11/17/5YPOhLtZJ5kRrbs-L-rYr.png!png';
const deleteIcon = 'https://staticcdn.boyuai.com/materials/2020/11/17/sp-Qqzi05wBD8EmqIPb2M.png!png';
const loadIcon = 'https://staticcdn.boyuai.com/materials/2020/11/17/j-NUwWSSCny5V8IggK9Nv.png!png';
const loadingIcon = 'https://staticcdn.boyuai.com/materials/2020/11/17/O_OHFZ4NZgqrVNFKUVeMj.png!png';

export {
  askActive,
  askIcon,
  noteActive,
  noteIcon,
  chatIcon,
  starIcon,
  chatActive,
  starActive,
  likeIcon,
  likeActive,
  allIcon,
  allActive,
  replyIcon,
  linkIcon,
  expandIcon,
  foldIcon,
  deleteIcon,
  loadIcon,
  loadingIcon,
};
