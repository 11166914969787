const savePlayerQualityHelper = (player, id) => () => {
    if (player) {
        try {
            const quality = player
                .getComponent('QualityComponent')
                .html.querySelector(`.current-quality`).dataset.def;
            if (quality) {
                localStorage.setItem(`${id}-aliplayer-quality`, quality);
            }
        } catch (error) {
            throw Error(
                'initPlayerSetting savePlayerVolumeHelper setTimeout error: ',
                error
            );
        }
    }
};

const savePlayerRateHelper = (player, id) => () => {
    if (player) {
        try {
            const rate = player
                .getComponent('RateComponent')
                .html.querySelector(`.rate-list .current`).dataset.rate;
            if (rate) {
                localStorage.setItem(`${id}-aliplayer-rate`, rate);
            }
        } catch (error) {
            throw Error(
                'initPlayerSetting savePlayerRateHelper error: ',
                error
            );
        }
    }
};

const savePlayerVolumeHelper = (player, id) => () => {
    if (player) {
        // player会在事件发生后更新volume，setTimeout保证volume正确
        setTimeout(() => {
            try {
                const volume = player.getVolume();
                if (volume) {
                    localStorage.setItem(`${id}-aliplayer-volume`, volume);
                }
            } catch (error) {
                throw Error(
                    'initPlayerSetting savePlayerVolumeHelper setTimeout error: ',
                    error
                );
            }
        }, 0);
    }
};

const onPlayerReadyHelper = (player, id) => () => {
    try {
        const rate = localStorage.getItem(`${id}-aliplayer-rate`);
        if (rate && rate !== '1.0') {
            player
                .getComponent('RateComponent')
                .html.querySelector(`.rate-list [data-rate='${rate}']`)
                .click();
        }
        const quality = localStorage.getItem(`${id}-aliplayer-quality`);
        if (quality && quality !== 'HD') {
            player.getComponent('QualityComponent').getQuality = () => {};
            player
                .getComponent('QualityComponent')
                .html.querySelector(`.quality-list [data-def='${quality}']`)
                .click();
        }
        const volume = localStorage.getItem(`${id}-aliplayer-volume`);
        if (volume && volume !== '1') {
            player.setVolume(volume);
        }
    } catch (error) {
        throw Error('initPlayerSetting on ready error: ', error);
    }
};

const initPlayerSettingCache = (player, id) => {
    try {
        const onReady = onPlayerReadyHelper(player, id);
        const savePlayerRate = savePlayerRateHelper(player, id);
        const savePlayerQuality = savePlayerQualityHelper(player, id);
        const savePlayerVolume = savePlayerVolumeHelper(player, id);

        const rateComp = player._el.querySelector('.rate-components');
        const qualityComp = player._el.querySelector('.quality-components');
        const volumeComp = player._el.querySelector('.prism-volume-control');

        player.on('ready', onReady);
        rateComp.addEventListener('click', savePlayerRate);
        qualityComp.addEventListener('click', savePlayerQuality);
        volumeComp.addEventListener('mouseup', savePlayerVolume);
        return () => {
            try {
                player.off('ready', onReady);
                rateComp.removeEventListener('click', savePlayerRate);
                qualityComp.removeEventListener('click', savePlayerQuality);
                volumeComp.removeEventListener('mouseup', savePlayerVolume);
            } catch (error) {
                throw Error(
                    'initPlayerSettingCache removeEventListener error: ',
                    error
                );
            }
        };
    } catch (error) {
        throw Error('initPlayerSettingCache error: ', error);
    }
};

export default initPlayerSettingCache;
