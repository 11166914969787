import React, { Component } from 'react';
import { Popconfirm, message, Tooltip } from 'antd';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { remote } from '@boyuai/utils';

import { UserContextModule } from '@boyuai/utils/es/context';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import Name from './name';
import Editor from './editor';
import PreviewEditor from './editor/preview-editor';
import CommentUserInfo from './comment-user';
import TimeAgo from './time-ago';

import {
  askActive,
  askIcon,
  noteActive,
  noteIcon,
  chatIcon,
  likeIcon,
  chatActive,
  starActive,
  likeActive,
  replyIcon,
  linkIcon,
  deleteIcon,
} from './type-image';

const { $delete, $put, isLogin, requestLogin } = remote;

const typeMap = new Map([
  ['question', { icon: askIcon, iconActive: askActive, title: '提问' }],
  ['talk', { icon: chatIcon, iconActive: chatActive, title: '闲聊' }],
  ['note', { icon: noteIcon, iconActive: noteActive, title: '笔记' }],
]);

export default class Comment extends Component {
  state = {
    reply: false,
    fold: false,
    deleted: false,
  };
  static contextType = UserContextModule.UserContext;
  constructor() {
    super();
    this.domRef = React.createRef();
  }
  get link() {
    const { routePrefix, comment } = this.props;
    return `${window.location.origin}${process.env.REACT_APP_SUB_DIRECTORY}/${routePrefix}/${comment.commentKey}#comment-${comment.uuid}`;
  }
  prepareReply = () => {
    if (!isLogin()) {
      message.error('请登录后再回复');
      return;
    }
    this.setState({ reply: true });
  };
  cancelReply = () => this.setState({ reply: false });
  onSubmit = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
    this.cancelReply();
    this.setCommentsFold(false, [data]);
  };
  delete = async () => {
    const { comment } = this.props;
    await $delete(`/comment/${comment.id}`);
    this.setState({ deleted: true });
  };
  like = async () => {
    if (!isLogin()) {
      return message.error('请登录后再点赞');
    }
    const { comment, updateCommentLike } = this.props;
    if (comment.likeLog) {
      await $put(
        `/comment/${comment.uuid}/like`,
        { like: false, likeId: comment.likeLog.id },
        { throwException: 'showMessage' }
      );
      updateCommentLike(comment.id, comment.like - 1, null);
    } else {
      const likeLog = await $put(
        `/comment/${comment.uuid}/like`,
        {
          like: true,
        },
        { throwException: 'showMessage' }
      );
      updateCommentLike(comment.id, comment.like + 1, likeLog);
    }
  };
  getHtmlId = () => {
    const { comment } = this.props;
    return `comment-${comment.uuid}`;
  };

  setCommentsFold = (fold, children) => {
    if (fold) {
      return;
    }
    document.dispatchEvent(
      new CustomEvent('comment-unfold', {
        detail: { children: children ?? this.props.comment.children },
      })
    );
  };

  componentDidUpdate(prevProps) {
    const { comment, showReply, commentsFold } = this.props;
    const active = window.location.hash === `#${this.getHtmlId()}`;
    if (active && !window.commentScrolled) {
      window.commentScrolled = true; // 防止重复滚动
      const yPosition = this.domRef.current.getBoundingClientRect().y;
      const isPaperSite = process.env.REACT_APP_SITE === 'videos';
      // course网站的header固定，所以需要减去header的高度64px，再减去评论区header的高度65px
      const scrollPosition = isPaperSite ? yPosition : yPosition - 65 - 65;
      window.scrollTo(0, scrollPosition);
    }
    if (showReply !== prevProps.showReply && comment.level !== 0) {
      this.setState({ fold: showReply });
    }

    if (
      prevProps.commentsFold[comment.id] === undefined &&
      commentsFold[comment.id] === false
    ) {
      this.setState({ fold: false });
    }
  }
  componentDidMount() {
    const { showReply, comment, commentsFold } = this.props;
    const fold = (comment.level !== 0 && showReply) || comment.level >= 3;
    let _fold = fold;
    if (commentsFold[comment.id] === false) {
      _fold = false;
    }
    this.setState({
      isSelected: comment.selected,
      fold: _fold,
    });
  }
  renderCommentHeader() {
    const { userName, createdAt, level, type, user } = this.props.comment;
    const { isSelected } = this.state;
    const { roles } = this.context.user;
    const isManager = roles && roles.includes('commentManager');
    // 评论的创建时间与当前时间的差值
    const distance = moment().diff(moment(createdAt), 'days');
    // 七天之前的时间
    const criteriaDate = moment().subtract(7, 'days');
    const displayedDate = distance < 7 ? moment(createdAt) : criteriaDate;
    // hover所显示的值
    const hoverDate = moment(createdAt).format('YYYY-MM-DD HH:mm:ss');

    return (
      <div className="comment-header">
        <CommentUserInfo user={user} userName={userName} />
        <TimeAgo date={displayedDate} title={hoverDate} />
        {level === 0 && this.renderTooltipIcon(type)}
        {isSelected && this.renderSelectedIcon()}
        {isManager && this.renderManagerSelect()}
        {isManager && this.renderDelete()}
      </div>
    );
  }

  renderSelectedIcon() {
    return (
      <Tooltip title="精华" overlayClassName="type-tooltip">
        <div className="type-icon-wrapper">
          <img src={starActive} alt={'star'} className="active-icon" />
        </div>
      </Tooltip>
    );
  }

  toggleStar = async () => {
    const { uuid } = this.props.comment;
    this.setState({ isSelected: !this.state.isSelected });
    await $put(`/comment/${uuid}/selected`, {
      selected: !this.state.isSelected,
    });
  };

  renderManagerSelect() {
    const { isSelected } = this.state;
    return (
      <div className="manage-star" onClick={this.toggleStar}>
        {isSelected ? '取消加精' : '加精'}
      </div>
    );
  }

  onConfirmDelete = async () => {
    await $delete(`/course/admin/comments/${this.props.comment.id}`);
    this.setState({ deleted: true });
  };

  renderDelete() {
    return (
      <Popconfirm
        title="确定要删除这条评论吗"
        onConfirm={this.onConfirmDelete}
        okText="删除"
        cancelText="取消"
      >
        <div className="delete-button">删除</div>
      </Popconfirm>
    );
  }

  renderTooltipIcon(type) {
    // 如果没有类型，则默认为闲聊
    const typeInfo = typeMap.get(type || 'talk');
    const { iconActive, title } = typeInfo;
    return (
      <Tooltip title={title} overlayClassName="type-tooltip">
        <div className="type-icon-wrapper">
          <img src={iconActive} alt={type} className="active-icon" />
        </div>
      </Tooltip>
    );
  }
  render() {
    const { anonymous, guest, name: userName, id: userId } = this.context.user;
    const { comment, routePrefix } = this.props;
    const { reply, fold, deleted } = this.state;
    const htmlId = this.getHtmlId();
    const active = window.location.hash === `#${htmlId}`;

    return (
      <React.Fragment>
        <li
          id={htmlId}
          className={active ? 'active' : ''}
          ref={this.domRef}
          data-level={comment.level}
        >
          <div
            className="comment-left-line"
            onClick={() => {
              this.setState((state) => ({ fold: !state.fold }));
              this.setCommentsFold(!this.state.fold);
            }}
          >
            {fold ? (
              <PlusCircleOutlined className="expand-icon" />
            ) : (
              <MinusCircleOutlined className="expand-icon" />
            )}
          </div>
          {this.renderCommentHeader()}
          {!fold && (
            <React.Fragment>
              {Object.keys(comment.content).length && !deleted ? (
                <PreviewEditor value={comment.content} />
              ) : (
                '**评论已删除**'
              )}
              {!reply && (
                <div className="comment-footer">
                  {
                    <button
                      onClick={this.like}
                      data-is-like={!!comment.likeLog}
                    >
                      <img
                        src={comment.likeLog ? likeActive : likeIcon}
                        alt="like"
                      />
                      {comment.like || ''}
                    </button>
                  }
                  {
                    <button onClick={this.prepareReply} data-is-like={false}>
                      <img src={replyIcon} alt="reply" />
                      回复
                    </button>
                  }
                  {
                    <CopyToClipboard
                      text={this.link}
                      onCopy={() => message.success('评论链接已复制到剪贴板')}
                    >
                      <button data-is-like={false}>
                        <img src={linkIcon} alt="link" />
                        链接
                      </button>
                    </CopyToClipboard>
                  }
                  {comment.userId === userId && (
                    <Popconfirm
                      title="确定要删除这条评论吗"
                      onConfirm={this.delete}
                      okText="删除"
                      cancelText="取消"
                    >
                      <button data-is-like={false}>
                        <img src={deleteIcon} alt="delete" />
                        删除
                      </button>
                    </Popconfirm>
                  )}
                </div>
              )}
              {reply && (
                <div className="editor-with-mask">
                  {!guest && !userName && !anonymous && (
                    <div className="editor-disable-mask">
                      <Name />
                    </div>
                  )}
                  {guest && (
                    <div className="editor-disable-mask">
                      请先 <span onClick={requestLogin}>登录</span> 后发表评论
                    </div>
                  )}
                  <Editor
                    commentKey={comment.commentKey}
                    routePrefix={routePrefix}
                    parentId={comment.id}
                    onCancel={this.cancelReply}
                    onSubmit={this.onSubmit}
                  />
                </div>
              )}
              {this.props.children}
            </React.Fragment>
          )}
        </li>
        {comment.level === 0 && <div className="split-line" />}
      </React.Fragment>
    );
  }
}
