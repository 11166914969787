import React, { Component } from 'react';
import { message, Upload, Button } from 'antd';
import { remote } from '@boyuai/utils';
import ImgCrop from 'antd-img-crop';

const { API_ROOT, getHeaders } = remote;

const beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 8;
    if (!isLt2M) {
        message.error('支持8MB以内的照片!');
    }
    return isLt2M;
};
export default class UserFormItemUpload extends Component {
    render() {
        return (
            <ImgCrop modalTitle="裁剪头像">
                <Upload
                    {...this.props}
                    name="file"
                    showUploadList={false}
                    accept="image/*"
                    multiple={false}
                    action={`${API_ROOT}${this.props.endpoint}`}
                    headers={getHeaders()}
                    beforeUpload={beforeUpload}
                >
                    <Button className="portrait-button" type="primary" ghost>
                        更换头像
                    </Button>
                </Upload>
            </ImgCrop>
        );
    }
}
