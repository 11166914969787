/**
 * TODO: 使用 store.js 重构
 */
var Storage = /** @class */ (function () {
    function Storage(namespace) {
        var isServer = typeof window === 'undefined';
        if (isServer) {
            throw Error('Storage can only be used in browser');
        }
        this.namespace = namespace;
    }
    Storage.prototype._getKey = function (key) {
        return this.namespace ? this.namespace + "-" + key : key;
    };
    Storage.prototype._getStorage = function (key, type) {
        var _a;
        var s = type === 'local' ? localStorage : sessionStorage;
        var nsKey = this._getKey(key);
        var value = (_a = s.getItem(nsKey)) !== null && _a !== void 0 ? _a : '';
        try {
            var parsed = JSON.parse(value);
            if (parsed.expires) {
                // @ts-ignore
                if (new Date() - new Date(parsed.expires) > 0) {
                    s.removeItem(nsKey);
                    return null;
                }
                return parsed.value;
            }
            else {
                return parsed.value;
            }
        }
        catch (error) {
            return value;
        }
    };
    Storage.prototype.getCookie = function (key) {
        var keyEQ = this._getKey(key) + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ')
                c = c.substring(1, c.length);
            if (c.indexOf(keyEQ) === 0)
                return c.substring(keyEQ.length, c.length);
        }
        return null;
    };
    Storage.prototype.getLocalStorage = function (key) {
        return this._getStorage(key, 'local');
    };
    Storage.prototype.getSessionStorage = function (key) {
        return this._getStorage(key, 'session');
    };
    /**
     * 获取数据
     * @param {string} key - 存储的 key
     * @param {{type: 'local' | 'session' | 'cookie'}} options - 更多选项
     * @param {string} [options.type=local] - 存储的类型，默认为 local
     */
    Storage.prototype.get = function (key, options) {
        if (options === void 0) { options = { type: 'local' }; }
        var type = options.type;
        switch (type) {
            case 'session':
                return this._getStorage(key, type);
            case 'cookie':
                return this.getCookie(key);
            case 'local':
            default:
                return this._getStorage(key, 'local');
        }
    };
    Storage.prototype.setStorage = function (key, value, type, exp, days) {
        var s = type === 'local' ? localStorage : sessionStorage;
        var expires = '';
        if (exp) {
            var date = new Date(exp);
            expires = date.toUTCString();
        }
        else if (days) {
            var date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = date.toUTCString();
        }
        var valueObj;
        try {
            valueObj = JSON.stringify({ value: value, expires: expires });
        }
        catch (error) {
            valueObj = String(value);
        }
        s.setItem(this._getKey(key), valueObj);
    };
    Storage.prototype.setCookie = function (key, value, exp, days) {
        var expires = '';
        var date;
        if (exp) {
            date = new Date(exp);
            expires = '; expires=' + date.toUTCString();
        }
        else if (days) {
            date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = '; expires=' + date.toUTCString();
        }
        document.cookie =
            this._getKey(key) + '=' + (value || '') + expires + '; path=/';
    };
    /**
     * 存储数据，如果 options.exp he options.days 为空则不设置过期
     * @param {string} key - 存储的 key
     * @param {any} value - 存储的 key
     * @param {{type: 'local' | 'session' | 'cookie', exp: Date, days: number}} options - 更多选项
     * @param {string} options.type - 存储的类型，默认为 local
     * @param {date} options.exp - 过期日期
     * @param {number} options.days - x天后过期，如果和 exp 同时传入，以 exp 为准
     */
    Storage.prototype.set = function (key, value, options) {
        if (options === void 0) { options = {
            type: 'local',
        }; }
        var type = options.type, exp = options.exp, days = options.days;
        switch (type) {
            case 'session':
                return this.setStorage(key, value, type, exp, days);
            case 'cookie':
                return this.setCookie(key, value, exp, days);
            case 'local':
            default:
                return this.setStorage(key, value, 'local', exp, days);
        }
    };
    Storage.prototype._removeStorage = function (key, type) {
        var s = type === 'local' ? localStorage : sessionStorage;
        s.removeItem(this._getKey(key));
    };
    Storage.prototype.removeCookie = function (key) {
        document.cookie =
            this._getKey(key) + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    };
    /**
     * 移除数据
     * @param {string} key - 存储的 key
     * @param {{type: 'local' | 'session' | 'cookie'}} options - 更多选项
     * @param {string} options.type - 存储的类型，默认为 local
     */
    Storage.prototype.remove = function (key, options) {
        if (options === void 0) { options = { type: 'local' }; }
        switch (options.type) {
            case 'session':
                return this._removeStorage(key, options.type);
            case 'cookie':
                return this.removeCookie(key);
            case 'local':
            default:
                return this._removeStorage(key, 'local');
        }
    };
    Storage.prototype._clearStorage = function (type) {
        var s = type === 'local' ? localStorage : sessionStorage;
        if (!this.namespace) {
            s.clear();
            return;
        }
        var keys = [];
        for (var i = 0; i < s.length; i++) {
            var key = s.key(i);
            if ((key === null || key === void 0 ? void 0 : key.indexOf(this.namespace)) === 0) {
                keys.push(key);
            }
        }
        keys.forEach(function (key) {
            s.removeItem(key);
        });
    };
    /**
     * 清除 namespace 下全部 localStorage 的数据
     * 如果 namespace 为空，则清空全部 localStorage
     */
    Storage.prototype.clearLocal = function () {
        this._clearStorage('local');
    };
    /**
     * 清除 namespace 下全部 sessionStorage 的数据
     * 如果 namespace 为空，则清空全部 sessionStorage
     */
    Storage.prototype.clearSession = function () {
        this._clearStorage('session');
    };
    /**
     * 清除 namespace 下全部 cookie 的数据
     * 如果 namespace 为空，则清空全部 cookie
     */
    Storage.prototype.clearCookie = function () {
        var keys = [];
        var keyValuePairs = document.cookie.split(';');
        for (var i = 0; i < keyValuePairs.length; i++) {
            var key = keyValuePairs[i].substring(0, keyValuePairs[i].indexOf('='));
            if (!this.namespace || key.indexOf(this.namespace) === 0) {
                keys.push(key);
            }
        }
        keys.forEach(function (key) {
            document.cookie = key + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
        });
    };
    /**
     * 清除 namespace 下全部 localStorage, sessionStorage 和 cookie 的数据
     * 如果 namespace 为空，则清空全部 localStorage
     */
    Storage.prototype.clearAll = function () {
        this.clearLocal();
        this.clearSession();
        this.clearCookie();
    };
    return Storage;
}());
export { Storage };
var SingletonNSStorage = {};
/**
 * 返回单例的ns本地存储
 * @param {string} namespace
 * @returns {Storage}
 */
function getStorage(namespace) {
    if (SingletonNSStorage[namespace]) {
        return SingletonNSStorage[namespace];
    }
    SingletonNSStorage[namespace] = new Storage(namespace);
    return SingletonNSStorage[namespace];
}
export { getStorage };
export default getStorage;
