import React, { useState } from 'react';
import { Switch } from 'antd';

export default function useCommentSwitch() {
    const [value, setValue] = useState(true);
    const node = <div className="switch-wrapper">
        <Switch
            onChange={() => setValue(prev => !prev)}
        />
        <span data-show-reply={value}>仅显示主贴</span>
    </div>;
    return [value, node];
}
