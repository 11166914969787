import React, {useCallback} from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';

export default function CommentCardTitle({
    count,
    countAll,
    filteredType,
    typeFilterNode,
    ShowReply,
    backToTop,
    keyword,
    setKeyword,
}) {
    const displayCount = filteredType.type === 'all' && !keyword ? countAll : count;
    const displayTip = filteredType.type === 'all' ? '讨论' : filteredType.tip;

    const debounceSetKeyword = useCallback(
        debounce(value => setKeyword(value), 500),
        []
    );
    return (
        <div className="comment-card-title">
            <div className="left-wrapper">
                讨论区({displayCount}条{displayTip}){typeFilterNode}
            </div>
            {backToTop}
            <Input
                className="search"
                prefix={<SearchOutlined />}
                placeholder="请输入想搜索的内容"
                allowClear
                onChange={(e)=>debounceSetKeyword(e.target.value)}
            />
            {ShowReply}
        </div>
    );
}
