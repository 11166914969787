/**
 * TODO: 使用 @boyuai/markdown 替换
 */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { remote } from '@boyuai/utils';
import Vditor from 'vditor';

import 'vditor/dist/index.css';
import './base-editor.less';

const { getHeaders, API_ROOT } = remote;

const BaseEditor = forwardRef(({ commentKey, onInput }, ref) => {
  const editorRef = useRef(null);
  const [editorId] = useState(new Date().getTime()); // 确保每一个编辑器实例有自己的id

  useEffect(() => {
    if (!editorId || editorRef.current !== null) {
      return;
    }
    editorRef.current = new Vditor(`vditor-editor-${editorId}`, {
      after: () => {
        // typeof onInit === 'function' && onInit(editorRef.current.getValue());
        // disabled && editorRef.current.disabled();
      },
      input: text => {
        typeof onInput === 'function' && onInput(text);
      },
      theme: 'classic',
      cdn: 'https://opendl.boyuai.com/scripts',
      placeholder: '留下你的评论',
      minHeight: 180,
      cache: { enable: false },
      toolbar: [
        'emoji',
        'headings',
        'bold',
        'italic',
        'strike',
        '|',
        'list',
        'ordered-list',
        'outdent',
        'indent',
        'code',
        {
          name: 'inline-code',
          prefix: '\n$$',
          suffix: '\n',
          tipPosition: 'n',
          tip: '插入数学公式'
        },
        'quote',
        '|',
        'link',
        'upload',
        '|',
        'edit-mode'
      ],
      upload: {
        max: 8 * 1024 * 1024,
        headers: getHeaders(),
        url: `${API_ROOT}/comment/${commentKey}/images`,
        accept: 'image/*',
        fieldName: 'file',
        multiple: false,
        format: (files, responseText) => {
          const res = JSON.parse(responseText);
          return JSON.stringify({
            msg: '',
            code: 0,
            data: {
              errFiles: [],
              succMap: {
                [files[0] ? files[0].name : 'image.png']: res.url
              }
            }
          });
        },
        linkToImgUrl: `${API_ROOT}/comment/${commentKey}/images/from-url`
      },
      preview: {
        math: {
          inlineDigit: true
        },
        actions: ['desktop', 'tablet', 'mobile']
      },
      tab: '    ',
      width: '100%'
    });
    return () => {
      editorRef.current.destory?.();
    };
  }, [commentKey, editorId, onInput]);

  useImperativeHandle(ref, () => ({
    reset() {
      editorRef.current.setValue('');
    }
  }));

  return <div id={`vditor-editor-${editorId}`} className="base-editor" />;
});

export default BaseEditor;
