import { getStorage } from '@boyuai/storage';

function getVideoTime(duration) {
    let secondTotal = Math.round(duration);

    let hour = Math.floor(secondTotal / 3600);
    let minute = Math.floor((secondTotal - hour * 3600) / 60);

    let second = secondTotal - hour * 3600 - minute * 60;

    if (minute < 10) {
        minute = '0' + minute;
    }
    if (second < 10) {
        second = '0' + second;
    }
    return hour === 0
        ? minute + ':' + second
        : hour + ':' + minute + ':' + second;
}

const defaultGetLastTime = (id, memoryVideo) => {
    return getStorage(id).get(memoryVideo);
};

const defaultSetLastTime = (id, memoryVideo, time) => {
    getStorage(id).set(memoryVideo, time);
};

let hasMemoryDisplay = false;
export default function initPlayerLastTime(
    player,
    id,
    getLastTime = defaultGetLastTime,
    setLastTime = defaultSetLastTime
) {
    const playerOptions = player.getOptions();
    const memoryVideo = playerOptions.source
        .replace(/\?.*$/, '')
        .match(/boyuai\.com\/.*\//)[0]; // 根据视频 vid 或者 source 去存储

    let memoryTime = getLastTime(id, memoryVideo);
    memoryTime = memoryTime ? parseInt(memoryTime) : 0;
    if (memoryTime !== null && memoryTime !== 0 && !hasMemoryDisplay) {
        // 标识记忆播放是否有触发, 解决清晰度切换也会触发记忆播放的问题
        // hasMemoryDisplay = true;
        let memoryVideoTime = getVideoTime(memoryTime);
        //判断是否是视频播放完了，如果没到，就有拼接的跳转播放
        if (memoryTime !== parseInt(player._duration)) {
            let memoryDomString = `
                <div class="fe-player-tooltip-wrap">
                    <div class="tooltip">
                        <i class="iconfont icon-close"></i>
                        <span>上次看到</span>
                        <span>${memoryVideoTime}</span>
                        <span class="action play-jump">跳转播放</span>
                    </div>
                </div>`;
            const ele = document.createElement('div');
            ele.innerHTML = memoryDomString;
            player._el.appendChild(ele);

            let timeoutMemory = setTimeout(() => {
                ele.innerHTML = '';
            }, 15000);

            player._el.querySelector('.icon-close').onclick = () => {
                ele.innerHTML = '';
                clearTimeout(timeoutMemory);
            };

            player._el.querySelector('.play-jump').onclick = () => {
                player.seek(memoryTime);
                if (player.getStatus() !== 'playing') {
                    player.play();
                }
                ele.innerHTML = '';
                clearTimeout(timeoutMemory);
            };
        }
    }

    const setMemory = () => {
        const playerOptions = player.getOptions();
        const memoryVideo = playerOptions.source
            .replace(/\?.*$/, '')
            .match(/boyuai\.com\/.*\//)[0];
        const time = player.getCurrentTime();
        if (time > 0) setLastTime(id, memoryVideo, player.getCurrentTime());
    };

    const onVisibilitychange = () => {
        if (
            document.visibilityState === 'hidden' &&
            player.getCurrentTime() !== 0
        ) {
            setMemory();
        }
    };

    const onBeforeunload = () => {
        if (player.getCurrentTime() !== 0) {
            setMemory();
        }
    };

    // player.on('ready', onReady);
    document.addEventListener('visibilitychange', onVisibilitychange);
    window.addEventListener('beforeunload', onBeforeunload);

    return () => {
        try {
            setMemory();
            document.removeEventListener(
                'visibilitychange',
                onVisibilitychange
            );
            window.removeEventListener('beforeunload', onBeforeunload);
        } catch (error) {
            throw Error('playerLastTime unmount callback error: ', error);
        }
    };
}
