import React, { useCallback, useRef } from 'react';
import BottomBar from './bottom-bar';
import BaseEditor from './base-editor';

// todo: 移动端适配

export default function Editor({
  commentKey,
  routePrefix,
  parentId,
  onCancel,
  onSubmit,
}) {
  const valueRef = useRef(null);
  const editorRef = useRef(null);
  const handleInput = useCallback((text) => {
    valueRef.current = text;
  }, []);
  const handleSubmit = (comment) => {
    editorRef.current.reset();
    typeof onSubmit === 'function' && onSubmit(comment);
  };
  return (
    <div className="ui-comment-editor-wrapper">
      <BaseEditor
        commentKey={commentKey}
        onInput={handleInput}
        ref={editorRef}
      />
      <BottomBar
        value={valueRef}
        commentKey={commentKey}
        parentId={parentId}
        onCancel={onCancel}
        routePrefix={routePrefix}
        onSubmit={handleSubmit}
      />
    </div>
  );
}
