import { useEffect } from 'react';

export default function useShortcut(playerInstance) {
    useEffect(() => {
        const handler = e => {
            if (e.target.nodeName === 'BODY') {
                // 避免在其他输入框触发
                const player = playerInstance.current;
                if (!player) {
                    return;
                }
                let used = false;
                // const status = player.getStatus();
                if (e.keyCode === 32) {
                    // 空格
                    used = true;
                    // 模拟点击操控栏的播放按钮，让暂停时显示 big-play-btn
                    // eslint-disable-next-line no-undef
                    const btn = document.querySelector('.prism-play-btn');
                    if(btn) btn.click();
                } else if (e.keyCode === 37) {
                    // ⬅️
                    used = true;
                    const current = player.getCurrentTime();
                    player.seek(current > 15 ? current - 15 : 0);
                } else if (e.keyCode === 39) {
                    // ➡️
                    used = true;
                    const duration = player.getDuration();
                    const current = player.getCurrentTime();
                    player.seek(
                        current < duration - 15 ? current + 15 : duration
                    );
                }
                if (used) {
                    e.preventDefault();
                }
            }
        };
        window.addEventListener('keydown', handler);
        return () => {
            window.removeEventListener('keydown', handler);
        };
    }, [playerInstance]);
}
